import React, { useContext } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import "./learn.css";
import { post } from "../../utils/fetch.js";
import useScrollToTop from "../../utils/useScrollToTop.js";
import { PageContext, usePageActionHandler } from "../../components/Page.jsx";
import { actionTypes } from "../../actions.js";
import ContentEditor from "../template/ContentEditor.jsx";
import axios from "axios";

const ContentRenderer = ({ viewedContent }) => {
  useScrollToTop();
  const { setError } = useContext(PageContext);
  const { courseId } = useParams();
  const { addAction, removeAction } = usePageActionHandler();

  const handleUpdateKeypointContent = async (delta, text) => {
    const action = `updateSubmoduleContent`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId,
      keypointId: viewedContent?.id,
      content: delta,
      text,
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const uploadImg = async (file) => {
    const filename = encodeURIComponent(file.name);
    const action = "createUploadImgPresigned";
    try {
      addAction(action);
      const result = await post(actionTypes[action].api, {
        filename,
        courseId,
      });
      const { url, fields } = result;
      const formData = new FormData();
      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });
      await axios.post(url, formData);
      removeAction(action);
      return `${url}${fields.key}`;
    } catch (error) {
      setError(error);
      removeAction(action);
    }
  };

  return (
    <ContentEditor
      delta={viewedContent?.delta}
      html={viewedContent?.content}
      onSave={(delta) => {
        handleUpdateKeypointContent(delta);
      }}
      onUploadImg={async (file) => {
        return await uploadImg(file);
      }}
    />
  );
};

export default ContentRenderer;
