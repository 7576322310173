import { Button, Tooltip } from "antd";
import { CrownOutlined } from "@ant-design/icons";

const TooltipButton = ({
  title,
  customIcon,
  size = "small",
  text,
  type = "ghost",
  onClick,
  icon: Icon,
  style,
  disabled,
  showPremiumLogo,
}) => {
  return (
    <Tooltip title={title}>
      <Button
        size={size}
        type={type}
        onClick={onClick}
        style={style}
        disabled={disabled}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            gap: 10,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {showPremiumLogo && (
            <CrownOutlined style={{ fontSize: 11, color: "gold" }} />
          )}
          {Icon && <Icon />}
          {customIcon}
          {text}
        </div>
      </Button>
    </Tooltip>
  );
};

export default TooltipButton;
