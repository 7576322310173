const TabLabel = ({ label }) => {
  return (
    <div
      style={{
        whiteSpace: window.innerWidth > 800 ? "break-spaces" : "unset",
        textAlign: "left",
      }}
    >
      {label}
    </div>
  );
};

export default TabLabel;
