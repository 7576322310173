import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { post } from "../../utils/fetch";
import "./courseTableContent.css";

const CourseTableOfContents = () => {
  const { courseId } = useParams();
  const [error, setError] = useState(null);
  const [modules, setModules] = useState([]);
  const [submodules, setSubmodules] = useState([]);

  useEffect(() => {
    getCoverData();
  }, []);

  const getCoverData = async () => {
    await post("/pdf-get-table-of-contents", {
      courseId,
    })
      .then((res) => {
        setModules(res.data.module);
        setSubmodules(res.data.keypoint);
      })
      .catch((err) => {
        setError(JSON.stringify({ error: err }));
      });
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {error && <div>{error}</div>}
      <div
        className="course-table-contents"
        style={{ margin: "auto", width: 796, backgroundColor: "white" }}
      >
        <h2 style={{ fontFamily: "Outfit", marginBottom: 32, fontSize: 40 }}>
          Table of Contents
        </h2>
        <ol style={{ marginLeft: 10 }}>
          {modules.map((module) => {
            return (
              <div key={module.id} style={{ marginBottom: 15 }}>
                <li
                  style={{
                    fontFamily: "Outfit",
                    fontWeight: 500,
                    fontSize: 18,
                    marginBottom: 8,
                  }}
                >
                  {module.title}
                </li>
                <ul style={{ marginBottom: 10 }}>
                  {submodules
                    .filter((sub) => sub.module_id === module.id)
                    .map((sub) => {
                      return (
                        <li key={sub.id} style={{ fontSize: 17 }}>
                          {sub.title}
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          })}
        </ol>
      </div>
    </div>
  );
};

export default CourseTableOfContents;
