import { Button, InputNumber, Slider } from "antd";
import { useContext, useState } from "react";
import { PageContext } from "../../components/Page";

const PriceSlider = ({
  decimalInputValue,
  setDecimalInputValue,
  handleGenerateBuyTokenPaymentLink,
  pricePer100kTokens
}) => {
  const { isActionInProgress } = useContext(PageContext);
  const minValue = pricePer100kTokens === 6 ? 100000 : 200000;
  const [inputValue, setInputValue] = useState(minValue);

  const onChange = (newValue) => {
    setInputValue(newValue);
    setDecimalInputValue(newValue / 100000 * pricePer100kTokens);
  };

  const onChangeDecimalInput = (value) => {
    if (isNaN(value)) {
      return;
    }
    setDecimalInputValue(value);
    setInputValue(value * (100000 / pricePer100kTokens));
  };

  return (
    <div style={{ display: "flex", gap: 20, flexDirection: 'column', alignItems: 'end' }}>
      <div style={{ width: "100%" }}>
        <Slider
          min={minValue}
          max={2000000}
          step={100000}
          onChange={onChange}
          value={typeof inputValue === "number" ? inputValue : 0}
          tooltip={{
            formatter: (value) => {
              if (value >= 1000000) {
                return `${(value / 1000000).toFixed(1)}m`;
              }
              return `${value / 1000}k`;
            },
            parser: (value) => {
              if (value.includes("m")) {
                return value.replace("m", "") * 1000000;
              }
              return value.replace("k", "") * 1000;
            },
          }}
        />
      </div>
      <div style={{ display: "flex", gap: 10, maxWidth: 260 }}>
        <div>
          <p style={{ textAlign: "center" }}>Token</p>
          <InputNumber
            min={minValue}
            max={2000000}
            step={100000}
            formatter={(value) => {
              if (value >= 1000000) {
                return `${(value / 1000000).toFixed(1)}m`;
              }
              return `${(value / 1000).toFixed(0)}k`;
            }}
            parser={(value) => {
              if (value.includes("m")) {
                return value.replace("m", "") * 1000000;
              }
              return value.replace("k", "") * 1000;
            }}
            value={inputValue}
            onChange={onChange}
          />
        </div>
        <div style={{ display: "flex", gap: 10, alignItems: "end" }}>
          <div>
            <p style={{ textAlign: "center" }}>Price</p>
            <InputNumber
              style={{ width: "100%" }}
              prefix="$"
              min={minValue / 100000 * pricePer100kTokens}
              max={200}
              step={pricePer100kTokens}
              value={decimalInputValue}
              onChange={onChangeDecimalInput}
            />
          </div>
          <Button
            disabled={isActionInProgress("createTokenPaymentLink")}
            loading={isActionInProgress("createTokenPaymentLink")}
            onClick={handleGenerateBuyTokenPaymentLink}
          >
            Pay
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PriceSlider;
