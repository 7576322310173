const { Tabs } = require("antd");

import React from "react";
import Modules from "./Modules";

const CustomizeCoursePage = () => {
  const [activeTab, setActiveTab] = React.useState("1");
  const [showNewModuleForm, setShowNewModuleForm] = React.useState(false);
  const [enableDragModule, setEnableDragModule] = React.useState(false);

  return (
    <Tabs
      size="small"
      type="card"
      tabPosition="top"
      activeKey={activeTab}
      onChange={(key) => {
        setActiveTab(key);
      }}
      tabBarExtraContent={{
        right: activeTab === "1" && (
          <Modules.Menu
            enableDragModule={enableDragModule}
            setEnableDragModule={setEnableDragModule}
            setShowNewModuleForm={setShowNewModuleForm}
          />
        ),
      }}
      items={[
        {
          key: "1",
          label: "Table of Contents",
          children: (
            <Modules
              enableDragModule={enableDragModule}
              showNewModuleForm={showNewModuleForm}
              setShowNewModuleForm={setShowNewModuleForm}
            />
          ),
        },
        {
          key: "2",
          label: "Content Template",
          children: <div>Content Template</div>,
        },
      ]}
    />
  );
};

export default CustomizeCoursePage;
