import React from "react";
import ReactDOM from "react-dom/client";
import { ConfigProvider } from "antd";
import { BrowserRouter } from "react-router-dom";
import firebaseApp from "./firebase";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

import "antd/dist/reset.css";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const referrerUrl = localStorage.getItem("referrerUrl");
if (!referrerUrl) {
  localStorage.setItem("referrerUrl", document.referrer);
}
getAuth(firebaseApp);
getAnalytics(firebaseApp);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ConfigProvider
      theme={{
        dark: true,
        token: {
          fontFamily: "Outfit",
        },
      }}
    >
      <App />
    </ConfigProvider>
  </BrowserRouter>,
);

reportWebVitals();
