import { Card, Input, Spin } from "antd";
import React, { useContext, useState } from "react";
import {
  DownOutlined,
  UpOutlined,
  LoadingOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { List, arrayMove, arrayRemove } from "react-movable";

import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import { PageContext, usePageActionHandler } from "../../components/Page";
import TabEditableCard from "../../components/TabEditableCard";
import { CourseContext } from "./Course";
import SubmoduleCard from "./SubmoduleCard";
import TooltipButton from "../../components/TooltipButton";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const ModuleCard = ({
  moduleIndex,
  draggableProps,
  moduleData,
  dragModuleModeEnabled,
}) => {
  const { isActionInProgress, setError, withConfirmation } =
    useContext(PageContext);
  const {
    getCourseDetails,
    courseData,
    handleSortModules,
    handleUpdateCourseModuleSuccess,
    handleSortSubmodules,
  } = useContext(CourseContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { contentId } = useParams();

  const [openSubmodulesCard, setOpenSubmodulesCard] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [showNewSubmoduleForm, setShowNewSubmoduleForm] = useState(false);

  const updateCourseModule = async (values) => {
    const action = "updateCourseModule";
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: contentId,
      moduleId: moduleData.id,
      title: values.title,
      description: values.description,
    })
      .then(() => {
        handleUpdateCourseModuleSuccess(
          moduleData.id,
          values.title,
          values.description,
        );
        setIsEdit(false);
      })
      .catch((err) => {
        setError(err);
      });
    removeAction(action);
  };

  const addNewSubmodule = async (values) => {
    const action = "addNewCourseSubmodule";
    addAction(action);
    await post(actionTypes[action].api, {
      moduleId: moduleData.id,
      courseId: contentId,
      title: values.title,
      description: values.description,
    })
      .then(async (res) => {
        await getCourseDetails().then(() => {
          removeAction(action);
          setShowNewSubmoduleForm(false);
        });
      })
      .catch((err) => {
        removeAction(action);
        setShowNewSubmoduleForm(false);
        setError(err);
      });
  };

  const onDeleteModule = () =>
    withConfirmation("deleteModule", async () => {
      handleSortModules(
        arrayRemove(courseData?.course?.course_module, moduleIndex),
        courseData?.course?.course_module[moduleIndex],
      );
    });

  return (
    <Card
      {...(dragModuleModeEnabled ? draggableProps : {})}
      style={{
        ...(dragModuleModeEnabled ? draggableProps.style : {}),
        cursor: dragModuleModeEnabled ? "grab" : undefined,
        width: "100%",
      }}
    >
      <div>
        <div
          style={{
            display: "flex",
            gap: 14,
            justifyContent: "space-between",
            alignItems: "baseline",
            flexDirection: window.innerWidth > 600 ? "row" : "column",
          }}
        >
          {!isEdit && <h3 style={{ margin: 0 }}>{moduleData.title}</h3>}
          {!dragModuleModeEnabled && !isEdit && (
            <div
              style={{
                display: "flex",
                gap: 5,
                marginLeft: "auto",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              <TooltipButton
                title="Add new lesson"
                type="default"
                onClick={() => {
                  setShowNewSubmoduleForm(!showNewSubmoduleForm);
                }}
                disabled={isActionInProgress()}
                icon={PlusOutlined}
              />
              <TooltipButton
                title="Edit module"
                type="default"
                onClick={() => {
                  setIsEdit(!isEdit);
                }}
                disabled={isActionInProgress()}
                icon={EditOutlined}
              />
              <TooltipButton
                title="Delete module"
                type="default"
                onClick={onDeleteModule}
                disabled={isActionInProgress()}
                icon={DeleteOutlined}
              />
              <TooltipButton
                title="Toggle lessons description"
                type="default"
                onClick={() => {
                  setOpenSubmodulesCard(!openSubmodulesCard);
                }}
                disabled={isActionInProgress()}
                icon={openSubmodulesCard ? UpOutlined : DownOutlined}
              />
            </div>
          )}
        </div>
        {!isEdit && !dragModuleModeEnabled && (
          <p style={{ margin: "30px 0 20px 0" }}>{moduleData.description}</p>
        )}
      </div>
      {!dragModuleModeEnabled && (
        <div style={{ display: "flex", flexDirection: "column" }}>
          {isEdit && (
            <TabEditableCard
              title="Edit module"
              style={{ marginTop: 20 }}
              onClose={() => {
                setIsEdit(false);
              }}
              unstyle
              allowClose
              editable
              formOnly
              onSubmit={updateCourseModule}
              isSubmitting={isActionInProgress("updateCourseModule")}
              form={{
                initialValues: {
                  title: moduleData.title,
                  description: moduleData.description,
                },
              }}
              data={[
                {
                  name: "title",
                  label: "Title",
                  input: Input,
                },
                {
                  name: "description",
                  label: "Description",
                  input: Input.TextArea,
                  inputProps: {
                    rows: 4,
                    maxLength: 1000,
                    showCount: true,
                  },
                },
              ]}
            />
          )}
          {!isEdit && (
            <Spin
              indicator={antIcon}
              spinning={isActionInProgress(["sortCourseSubmodules"])}
            >
              <List
                values={moduleData.keypoints || []}
                onChange={({ oldIndex, newIndex }) => {
                  handleSortSubmodules(
                    moduleIndex,
                    arrayMove(moduleData.keypoints, oldIndex, newIndex),
                  );
                }}
                renderList={({ children, props }) => (
                  <div
                    {...props}
                    style={{
                      display: "flex",
                      gap: 5,
                      flexWrap: "wrap",
                      marginTop: 10,
                      ...props.style,
                    }}
                  >
                    {showNewSubmoduleForm && (
                      <TabEditableCard
                        style={{ width: "100%", padding: 10 }}
                        title="Add new lesson"
                        allowClose
                        unstyle
                        editable
                        formOnly
                        onSubmit={addNewSubmodule}
                        onClose={() => {
                          setShowNewSubmoduleForm(false);
                        }}
                        isSubmitting={isActionInProgress(
                          "addNewCourseSubmodule",
                        )}
                        form={{
                          initialValues: {
                            title: "",
                            description: "",
                          },
                        }}
                        data={[
                          {
                            name: "title",
                            label: "Title",
                            input: Input,
                          },
                          {
                            name: "description",
                            label: "Description",
                            input: Input.TextArea,
                            inputProps: {
                              rows: 5,
                              maxLength: 4000,
                              showCount: true,
                              placeholder: `Enter your own text or highlight specific key points to further customize the AI-generated content to match your lesson topic.`,
                            },
                          },
                        ]}
                      />
                    )}
                    {children}
                  </div>
                )}
                renderItem={({ value, index, props }) => {
                  if (showNewSubmoduleForm) {
                    return null;
                  }

                  return (
                    <SubmoduleCard
                      draggableProps={props}
                      key={value.id}
                      submodule={value}
                      closeAll={openSubmodulesCard}
                      moduleId={moduleData.id}
                      moduleIndex={moduleIndex}
                      submoduleIndex={index}
                    />
                  );
                }}
              />
            </Spin>
          )}
        </div>
      )}
    </Card>
  );
};

export default ModuleCard;
