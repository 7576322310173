import { useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";

import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import { PageContext, usePageActionHandler } from "../../components/Page";
import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import Lessons from "./Lessons";
import { AppContext } from "../../App";
import OverviewEditor from "../../pages/template/OverviewEditor";

const Home = () => {
  const chatEndRef = useRef();
  const history = useHistory();
  const params = useParams();
  const { appState } = useContext(AppContext);
  const { setError, withConfirmation } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [courseOverviewConversation, setCourseOverviewConversation] = useState(
    JSON.parse(localStorage.getItem("courseOverviewConversation")) || [],
  );
  const [courseOverview, setCourseOverview] = useState(null);
  const sessionId = params.courseId || localStorage.getItem("sessionId");
  const [course, setCourse] = useState(null);
  const [extendLesson, setExtendLesson] = useState(false);
  const [tokenLeft, setTokenLeft] = useState(null);
  const [processingMessage, setProcessingMessage] = useState(null);

  useEffect(() => {
    getCourse();
  }, []);

  useEffect(() => {
    if (course?.overview) {
      scrollToBottom();
    }
  }, [course?.overview]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    if (appState?.user) {
      socket.emit("subscribeToStatusUpdate", appState?.user?.id);
      socket.on("statusUpdate", (socketData) => {
        const { data } = socketData;
        if (data.action === "tokenUpdate") {
          setTokenLeft(data.data.tokenLeft);
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [appState?.user]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    scrollToBottom();

    if (sessionId) {
      handleSetConversation(courseOverviewConversation);
      socket.emit("subscribeToStatusUpdate", sessionId);
      socket.on("statusUpdate", (socketData) => {
        const { data } = socketData;
        if (data.error) {
          getCourse();
          if (data.error.code === "not_enough_token") {
            setError(
              "Low token balance. Upgrade or top up your token to continue app access.",
            );
            return;
          }
          setError(data.error);
          return;
        }

        if (data.action === "streamingGenerateOverview") {
          setCourseOverview(data.data.reply);
          scrollToBottom();
        }

        if (data.action === "completeGenerateOverview") {
          setCourseOverview(data.data.reply);
          scrollToBottom();
          getCourse();
          removeAction("generateCourseOverview");
        }

        if (data.action === "processingGenerateLessonKeypoints") {
          setProcessingMessage(
            "[2/2] Generating key points for each modules. Please wait...",
          );
        }

        if (data.action === "generateModules") {
          getCourse();
          setProcessingMessage(null);
        }

        if (data.action === "generateSubmodules") {
          getCourse();
        }

        if (data.action === "submoduleContentGenerationUpdate") {
          getCourse();
        }
      });
    }

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, params.projectId, params.courseId]);

  const handleSetConversation = (conversation) => {
    localStorage.setItem(
      "courseOverviewConversation",
      JSON.stringify(conversation),
    );
    setCourseOverviewConversation(conversation);
  };

  const getCourse = async () => {
    const action = `getCourseDetails`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: params.courseId,
    })
      .then((res) => {
        setCourse(res.data.course);
        setCourseOverviewConversation(
          res.data.course.overview
            ? [{ role: "assistant", content: res.data.course.overview }]
            : res.data.course.overview_chat,
        );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
        scrollToBottom();
      });
  };

  const generateModules = () =>
    withConfirmation(
      "generateModules",
      async (action) => {
        addAction(action);
        setProcessingMessage(
          "[1/2] Generating modules. This will take some time. Please wait...",
        );
        if (window.innerWidth < 1000) {
          setExtendLesson(true);
        }
        await post(actionTypes[action].api, {
          courseId: params.courseId,
          apiKey: localStorage.getItem("openAiApiKeyV2"),
          aiModel: localStorage.getItem("aiModel"),
        })
          .then(async () => {
            await getCourse();
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            removeAction(action);
          });
      },
      !!course?.course_module.length,
    );

  const generateSubmodules = (module) =>
    withConfirmation(
      "generateSubmodules",
      async (action) => {
        addAction(action);
        await post(actionTypes[action].api, {
          courseId: params.courseId,
          moduleId: module.id,
          apiKey: localStorage.getItem("openAiApiKeyV2"),
          aiModel: localStorage.getItem("aiModel"),
        })
          .then(async (res) => {
            await getCourse();
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            removeAction(action);
          });
      },
      !!(module.keypoints.length > 0),
    );

  const generateContents = (moduleId, submodule) =>
    withConfirmation(
      "generateContent",
      async (action) => {
        addAction(action);
        await post(actionTypes[action].api, {
          courseId: params.courseId,
          moduleId,
          submoduleId: submodule?.id || undefined,
          apiKey: localStorage.getItem("openAiApiKeyV2"),
          aiModel: localStorage.getItem("aiModel"),
        })
          .then(async (res) => {
            await getCourse();
          })
          .catch((err) => {
            setError(err);
          })
          .finally(() => {
            removeAction(action);
          });
      },
      !!(submodule?.status_id === "ready"),
    );

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView();
  };

  const handleSortModules = async (newModules, deletedModule) => {
    const action = "sortCourseModules";
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: course.id,
      sortedModules: newModules.map((item) => item.id),
      deletedModuleId: deletedModule?.id || undefined,
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const handleSortSubmodules = async (
    moduleIndex,
    newSubmodules,
    deletedSubmodule,
  ) => {
    const action = "sortCourseSubmodules";
    addAction(action);
    await post(actionTypes[action].api, {
      moduleId: course.course_module[moduleIndex].id,
      courseId: course.id,
      sortedSubmodules: newSubmodules.map((item) => item.id),
      deletedSubmoduleId: deletedSubmodule?.id || undefined,
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const deleteCourse = () =>
    withConfirmation("deleteCourse", async (action) => {
      addAction(action);
      await post(actionTypes[action].api, {
        courseId: course.id,
      })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          removeAction(action);
          history.push(`/studio/project/${params.projectId}/course/new`);
        });
    });

  const handleUpdateOverview = async (delta, text) => {
    const action = `updateCourseOverview`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: course.id,
      delta,
      text,
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  return (
    <div style={{ display: "flex", padding: 10, gap: 10 }}>
      <div
        style={{
          width: window.innerWidth > 1000 ? "calc(100vw - 550px)" : "100vw",
          display: extendLesson ? "none" : "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "calc(100vh - 40px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            overflow: "auto",
            paddingRight: course?.overview ? 10 : 0,
          }}
        >
          <div
            style={{
              padding: 20,
              width: "100%",
              maxWidth: 800,
              alignSelf: "center",
            }}
          >
            <OverviewEditor
              markdown={courseOverview}
              delta={course?.overview_delta}
              html={course?.overview}
              onSave={handleUpdateOverview}
              onGenerateLesson={generateModules}
              extendLesson={extendLesson}
              setExtendLesson={setExtendLesson}
            />
          </div>
        </div>
      </div>
      <Lessons
        generateSubmodules={generateSubmodules}
        generateContents={generateContents}
        course={course}
        extendLesson={extendLesson}
        setExtendLesson={setExtendLesson}
        getCourse={getCourse}
        tokenLeft={tokenLeft}
        handleSortModules={handleSortModules}
        handleSortSubmodules={handleSortSubmodules}
        deleteCourse={deleteCourse}
        processingMessage={processingMessage}
      />
    </div>
  );
};

export default Home;
