import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { post } from "../../utils/fetch";
import CoverViewer from "../template/CoverViewer";

const CourseCover = () => {
  const { courseId } = useParams();
  const [delta, setDelta] = useState({});
  const [backgroundStyles, setBackgroundStyles] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    getCoverData();
  }, []);

  const getCoverData = async () => {
    await post("/pdf-get-cover-details", {
      courseId,
    })
      .then((res) => {
        setDelta(res.data.delta);
        setBackgroundStyles(res.data.background_styles);
      })
      .catch((err) => {
        setError(JSON.stringify({ error: err }));
      });
  };

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#EBECF0" }}>
      {error && <div>{error}</div>}
      <div style={backgroundStyles}>
        <CoverViewer delta={delta} />
      </div>
    </div>
  );
};

export default CourseCover;
