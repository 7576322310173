import { createContext, useState } from "react";
import LoadingMessage from "../../components/Loading.jsx";
import ErrorMsg from "../../components/ErrorMessage.jsx";
import useScrollToTop from "../../utils/useScrollToTop.js";
import { Alert, Button } from "antd";

// import { Menu } from '../home/Home';
import Login from "./Login.jsx";
import Register from "./Register.jsx";
import "./auth.css";
import MainMenu from "../../components/MainMenu";
import { Link } from "react-router-dom/cjs/react-router-dom.min.js";
import Forgot from "./ForgotPassword.jsx";

const PageContext = createContext(null);

const App = () => {
  const [fetchItems, setFetchItems] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);

  const handleSetFetchItems = (api) => {
    setFetchItems((prevFetchItems) => [...prevFetchItems, api]);
  };

  const handleRemoveFetchItems = (api) => {
    setFetchItems((prevFetchItems) =>
      prevFetchItems.filter((item) => item !== api),
    );
  };

  return (
    <PageContext.Provider
      value={{
        handleSetFetchItems,
        handleRemoveFetchItems,
        showErrorMsg: setErrorMsg,
      }}
    >
      <>
        <ErrorMsg error={errorMsg} />
        <LoadingMessage fetchItems={fetchItems} />
        <Signin />
      </>
    </PageContext.Provider>
  );
};

export { PageContext };

const Signin = () => {
  useScrollToTop();
  const [signinAction, setSigninAction] = useState("register");
  const [hideRegister, setHideRegister] = useState(false);
  const courseOverviewConversation =
    localStorage.getItem("courseOverviewConversation") &&
    JSON.parse(localStorage.getItem("courseOverviewConversation"));

  return (
    <div
      style={{
        minHeight: "100vh",
        margin: window.innerWidth > 600 ? 40 : 20,
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-evenly",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          gap: 50,
          flexWrap: "wrap",
          maxWidth: 900,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Link to="/home">
            <img style={{ width: 22 }} alt="chat2course" src="/logo.png" />
          </Link>
          <MainMenu />
        </div>
        <h1
          style={{
            textAlign: "center",
            color: "rgb(3, 144, 255)",
            fontSize: 32,
          }}
        >
          Sign in
        </h1>
        {courseOverviewConversation &&
          courseOverviewConversation.length > 0 && (
            <Alert
              style={{
                width: "100%",
                maxWidth: 600,
                alignSelf: "center",
                textAlign: "center",
              }}
              type="info"
              description="Your conversation is saved. Please register to proceed with your course creation."
            />
          )}
        <div style={{ maxWidth: 600, margin: "auto", width: "100%" }}>
          <div
            style={{
              display: "flex",
              gap: 10,
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button
              style={{
                width: "100%",
                color: signinAction === "login" ? "blue" : undefined,
              }}
              onClick={() => {
                setSigninAction("login");
              }}
            >
              Login
            </Button>
            <Button
              style={{
                width: "100%",
                color: signinAction === "register" ? "blue" : undefined,
              }}
              onClick={() => {
                setSigninAction("register");
              }}
            >
              Register
            </Button>
          </div>
          <Button
            type="link"
            onClick={() => {
              setSigninAction("forgot");
            }}
            style={{ marginTop: 10, alignSelf: "right" }}
          >
            Forgot your password?
          </Button>
        </div>
        {signinAction === "register" && (
          <Register hideRegister={hideRegister} />
        )}
        {signinAction === "login" && (
          <Login
            setSigninAction={setSigninAction}
            setHideRegister={setHideRegister}
          />
        )}
        {signinAction === "forgot" && (
          <Forgot
            setSigninAction={setSigninAction}
            setHideRegister={setHideRegister}
          />
        )}
      </div>
    </div>
  );
};

export default App;
