import React from "react";

const renderNestedComponents = (config) => {
  const { components } = config;

  const renderComponent = (Component, props, children) => {
    return React.createElement(Component, props, children);
  };

  const renderComponents = (components, index = 0) => {
    if (index === components.length) return null;

    let Component, props;

    if (typeof components[index] === "function") {
      Component = components[index];
      props = {};
    } else {
      Component = components[index].component;
      props = components[index].props;
    }

    const children = renderComponents(components, index + 1);

    return renderComponent(Component, props, children);
  };

  return renderComponents(components);
};

export default {
  renderNestedComponents,
};
