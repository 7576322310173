import { Skeleton } from "antd";

const Show = ({ show, children, rows = 1, style = {} }) => {
  if (!show) {
    return (
      <div style={{ padding: 10, width: "100%", ...style }}>
        <Skeleton paragraph={{ rows }} />
      </div>
    );
  }

  return children;
};

export default Show;
