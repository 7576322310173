import React from "react";
import { Button, Card, Tooltip } from "antd";
import Meta from "antd/es/card/Meta";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  CheckOutlined,
  RobotOutlined,
  UserOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useLocation } from "react-router-dom";

import "./homeV3.css";
import TokenInfo from "pages/pricing-v2/TokenInfoTooltip";
import useScrollToTop from "utils/useScrollToTop.js";
import MainMenu from "components/MainMenu.jsx";
import TruncatedText from "components/TruncatedText.jsx";
import { post } from "utils/fetch";
import { actionTypes } from "actions";
import MockEditor from "./MockEditor.jsx";

const welcomeChat = `
Hi! I'm your intelligent course development assistant. I'm going to help you transform your expertise into professional online courses. To get started, which primary language would you like to create your course in?
`;

const plan = {
  id: "v2_sub_pro",
  title: "Pro Plan",
  price: "20 / month",
  desc: "Transform your expertise into professional courses instantly with Chat2Course PRO, the ultimate AI course creation solution for educators and consultants.",
  features: [
    "Unlimited course creation",
    "Unlimited course storage",
    "Download course as PDF",
    "Fully customized course structure & content",
    "Cover template selection",
  ],
  purchaseBtnText: "Subscribe",
};

const testimonial = [
  {
    name: "Franco Gericke",
    img: "https://chat2stats.imgix.net/IMG_20230623_111237.jpg",
    comment:
      "Being a PhD student, I have found Chat2Course to be an incredibly useful tool in my academic journey. With its AI-powered personal course builder, I can craft a tailored and comprehensive learning experience that helps me manage my demanding research projects and presentations with ease. One of the most impressive features of Chat2Course is its integration of GPT-4 technology. The AI tutor is incredibly helpful in addressing complex subject matters and difficult questions that often arise during my research. Its interactive and adaptive nature has improved my ability to understand and explore advanced theoretical concepts, which has been instrumental in my aiding me in my academic endeavours. It provides golden nuggets of information in the way that you learn best. This way I am able to grasp complicated topics in a fraction of the time. All in all, Chat2Course is an indispensable resource for every student. Its adaptability, integration of advanced AI technology, and supportive community make it a valuable asset in navigating the challenges of your academic journey. If you are looking for a tool to streamline your studies, I highly recommend Chat2Course.",
    socialProfile: "https://www.researchgate.net/profile/Franco-Gericke",
  },
  {
    name: "Andrew J. Leavitt",
    img: "https://chat2stats.imgix.net/andrew.jpg",
    comment:
      "Thank you for this revolutionary product. I am absolutely thrilled. It has truly changed the way we conduct business.",
    socialProfile: "https://www.linkedin.com/in/andrew-leavitt-28a614194",
  },
  {
    name: "Ryan Kmetz",
    img: "https://chat2stats.imgix.net/ryan.jpg",
    comment:
      "Chat2Course enabled me to craft a bespoke GIS curriculum and course content for online learning. The tool helped me to aggregate my thoughts and produce a great foundation for the content.",
    socialProfile: "https://ryankmetz.com/",
  },
];

const pdf = [
  {
    url: "https://chat2stats.imgix.net/dg_mark_cjdwhc.png",
    download:
      "https://drive.google.com/file/d/1Vn8XtS2l0eEc9__B-_sYqXsz-4wua8PM/view?usp=sharing",
  },
  {
    url: "https://chat2stats.imgix.net/wkfowrof.png",
    download:
      "https://drive.google.com/file/d/1jUG_R_oAJqRMDUC3GkJxNvjISgIDAWIX/view?usp=sharing",
  },
];

export const Menu = () => {
  const history = useHistory();

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage: undefined,
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "center",
      }}
    >
      <img
        style={{
          cursor: "pointer",
          width: window.innerWidth > 600 ? 32 : 18,
          alignItems: "center",
          height: "100%",
        }}
        onClick={() => {
          history.push("/");
        }}
        alt="chat2course"
        src="/logo.png"
        aria-hidden="true"
      />
      <MainMenu />
    </div>
  );
};

const Home = () => {
  useScrollToTop();
  const history = useHistory();
  const location = useLocation();
  const subscribeSectionRef = React.useRef(null);
  const [isSubscribing, setIsSubscribing] = React.useState(false);
  const mainFontSize =
    window.innerWidth > 575 ? 74 : window.innerWidth > 420 ? 54 : 42;

  const userEmail = localStorage.getItem("email");
  const backgroundSize =
    window.innerWidth > 600 ? (2 / 3) * window.innerWidth : window.innerWidth;

  const refs = {
    subscribeSection: subscribeSectionRef,
  };

  React.useEffect(() => {
    if (location.hash) {
      const sectionId = location.hash.slice(1);
      const ref = refs[sectionId];
      if (ref && ref.current) {
        ref.current.scrollIntoView();
      }
    }
  }, [location]);

  const handleGeneratePaymentLink = async () => {
    const action = "createTokenPaymentLink";
    setIsSubscribing(true);
    await post(actionTypes[action].api, {
      productKey: "v3_pro_plan",
      url: window.location.protocol + "//" + window.location.host,
    })
      .then((res) => {
        window.location.replace(
          `${res.data.paymentLink}?prefilled_email=${encodeURIComponent(userEmail)}`,
        );
      })
      .catch((err) => {
        if (
          err?.response?.status === 400 &&
          err?.response?.data?.message === "already_subscribed"
        ) {
          history.push("/account");
        }
      });
  };

  return (
    <div
      className="home-v3"
      style={{
        backgroundSize,
        backgroundRepeat: "no-repeat",
        backgroundImage: `url("https://chat2stats.imgix.net/Untitled%20design%20(11).png")`,
      }}
    >
      <div
        style={{
          flexDirection: "column",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 1080,
            padding: "48px 24px",
            display: "flex",
            gap: 60,
            flexDirection: "column",
            alignSelf: "center",
            width: "100%",
            minHeight: "calc(100vh)",
          }}
        >
          <Menu />
          <div style={{ margin: "60px 0", minHeight: "calc(100vh - 380px)" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginBottom: 40,
              }}
            ></div>
            <div
              className="home-hero-font"
              style={{
                padding: window.innerWidth > 575 ? "0 40px" : "0 20px",
                marginBottom: 20,
                textAlign: "right",
                marginLeft: "auto",
                maxWidth: 800,
              }}
            >
              <b
                className="home-title"
                style={{
                  color: "#0390ff",
                  fontSize: mainFontSize,
                }}
              >
                Intelligent
                <br /> Course Development
                <br /> Assistant
              </b>
            </div>
            <div
              className="home-hero-font"
              style={{
                padding: window.innerWidth > 575 ? "0 40px" : "0 20px",
                marginBottom: window.innerWidth > 575 ? 120 : 80,
                textAlign: "right",
                marginLeft: "auto",
                maxWidth: window.innerWidth > 575 ? "600px" : "100%",
                width: "70%",
              }}
            >
              <div
                style={{
                  fontSize: window.innerWidth > 575 ? 24 : 18,
                  color: "#4e4e4e",
                }}
              >
                Transform your expertise into professional courses instantly
                with AI-Driven Solution
              </div>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: 900, alignSelf: "center" }}>
          <h2 className="section-title" style={{ width: "80%" }}>
            1. Start with a chat
          </h2>
          <div
            style={{
              marginTop: 40,
              display: "flex",
              flexDirection: "column",
              gap: 20,
              flexWrap: "wrap",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <p
              style={{
                ...styles.chatIntro,
                width: "80%",
                position: "relative",
                backgroundColor: "antiquewhite",
              }}
            >
              {welcomeChat}
              <div style={{ position: "absolute", top: -20, left: 30 }}>
                <RobotOutlined style={styles.botIcon} />
              </div>
            </p>
            <p
              style={{
                ...styles.chatIntro,
                padding: 30,
                width: "80%",
                marginLeft: "auto",
                position: "relative",
                backgroundColor: "#d0ebff",
              }}
            >
              English
              <div style={{ position: "absolute", top: -20, right: 30 }}>
                <UserOutlined style={styles.botIcon} />
              </div>
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              margin: 20,
            }}
          >
            <Link to="/get-started">
              <Button type="primary" size="large">
                Try now
              </Button>
            </Link>
          </div>
        </div>
        <div style={{ maxWidth: 900, alignSelf: "center", marginTop: 80 }}>
          <h2 className="section-title" style={{ width: "80%" }}>
            2. Customize the output
          </h2>
          <div
            style={{
              padding: 20,
              marginTop: 70,
              display: "flex",
              gap: 10,
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <MockEditor />
          </div>
        </div>
        <div style={{ marginTop: 80, marginBottom: 20 }}>
          <h2 className="section-title" style={{ width: "80%" }}>
            3. Export!
          </h2>
          <div
            style={{
              marginTop: 70,
              display: "flex",
              gap: 10,
              // flexWrap: "wrap",
              justifyContent: "center",
              padding: 10,
            }}
          >
            {pdf.map((item) => (
              <a
                key={item.id}
                href={item.download}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={item.url}
                  alt="xx"
                  style={{
                    boxShadow: "0 3px 3px 0px rgb(0 0 0 / 0.2)",
                    borderRadius: "3%",
                    width: "100%",
                    // height: '100%',
                  }}
                />
              </a>
            ))}
          </div>
        </div>
        <div
          style={{
            minHeight: 500,
            justifyContent: "end",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ margin: "120px 0" }}>
            <h2 className="section-title" style={{ width: "80%" }}>
              Hear from Our Users
            </h2>
            <div
              className="testimonial-card"
              style={{
                marginTop: 40,
                display: "flex",
                gap: 10,
                flexWrap: "wrap",
                justifyContent: "center",
                padding: 10,
              }}
            >
              {testimonial.map((item) => (
                <Card
                  key={item.id}
                  style={{
                    width:
                      window.innerWidth > 730 ? "calc(50% - 10px)" : "100%",
                    minWidth: 300,
                    maxWidth: window.innerWidth > 730 ? 350 : "unset",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <Meta
                    description={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 20,
                          textAlign: "center",
                          fontSize: 16,
                          color: "#1e1e1e",
                          lineHeight: 1.2,
                        }}
                      >
                        <TruncatedText str={item.comment} maxLength={200} />
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                            justifyContent: "end",
                          }}
                        >
                          <a
                            href={item.socialProfile}
                            target="_blank"
                            style={{ textAlign: "center" }}
                            rel="noreferrer"
                          >
                            {item.name}
                          </a>
                          <img
                            style={{
                              width: 50,
                              height: 50,
                              objectFit: "cover",
                              borderRadius: 100,
                            }}
                            alt="example"
                            src={item.img}
                          />
                        </div>
                      </div>
                    }
                  />
                </Card>
              ))}
            </div>
          </div>
          <div
            style={{
              background:
                "linear-gradient(180deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 0%, #6fddff 100%)",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 26,
                marginTop: 50,
              }}
            >
              <div
                style={{
                  marginLeft: 20,
                  width: "100%",
                  height: 1,
                  backgroundColor: "#e5e5e5",
                  borderRadius: 10,
                }}
              />
              <h2
                className="section-title"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: window.innerWidth > 600 ? 42 : 36,
                  margin: "0 auto 0 auto",
                }}
              >
                All-Access Pass:
              </h2>
              <div
                style={{
                  marginRight: 20,
                  width: "100%",
                  height: 1,
                  backgroundColor: "#e5e5e5",
                  borderRadius: 10,
                }}
              />
            </div>
            <h2
              ref={subscribeSectionRef}
              className="section-title"
              style={{
                width: "80%",
                fontSize: mainFontSize,
                margin: "0 auto 50px auto",
              }}
            >
              Upgrade to Pro
            </h2>
            <div
              style={{
                display: "flex",
                gap: 20,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
              }}
            >
              <Card
                key={plan.id}
                style={{ width: "100%", maxWidth: 900, marginBottom: 120 }}
              >
                <div style={styles.card}>
                  <div style={{ maxWidth: 400, padding: "0 20px" }}>
                    <div style={styles.cardHeader}>
                      <h2>{plan.title}</h2>
                      <span style={styles.cardTitle}>${plan.price}</span>
                    </div>
                    <p style={styles.cardDescription}>{plan.desc}</p>
                    <div style={styles.cardButtonContainer}>
                      <Button
                        size="large"
                        loading={isSubscribing}
                        onClick={() => {
                          if (localStorage.getItem("accessToken")) {
                            handleGeneratePaymentLink(plan.id);
                          } else {
                            history.push("/signin");
                          }
                        }}
                        style={styles.cardButton}
                      >
                        <span style={{ margin: "auto" }}>
                          {plan.purchaseBtnText}
                        </span>
                      </Button>
                    </div>
                  </div>
                  <div style={styles.divider} />
                  <div
                    style={{ margin: "auto", minWidth: 250, padding: "0 20px" }}
                  >
                    {plan.features.map((feature) => (
                      <div key={feature.id} style={styles.cardFeatureContainer}>
                        <CheckOutlined style={styles.cardFeatureIcon} />
                        <span style={{ fontSize: 15 }}>{feature}</span>
                      </div>
                    ))}
                    <div style={styles.cardFeatureContainer}>
                      <CheckOutlined style={styles.cardFeatureIcon} />
                      <span>500k AI tokens/month</span>
                      <Tooltip
                        trigger="click"
                        overlayInnerStyle={{ maxWidth: 400, width: "100%" }}
                        title={TokenInfo}
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                    <div style={styles.cardFeatureContainer}>
                      <CheckOutlined style={styles.cardFeatureIcon} />
                      <span>Use your own OpenAI API key (Optional)</span>
                    </div>
                    <div style={styles.cardFeatureContainer}>
                      <CheckOutlined style={styles.cardFeatureIcon} />
                      <span>Cancel anytime</span>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  botIcon: {
    padding: "10px",
    backgroundColor: "white",
    borderRadius: "100px",
    border: "1px solid black",
    fontSize: 20,
  },
  chatIntro: {
    backgroundColor: "aliceblue",
    padding: "20px 36px 36px 36px",
    borderRadius: "20px",
    fontFamily: "Outfit",
    fontWeight: "300",
    maxWidth: "900px",
    whiteSpace: "break-spaces",
    margin: "0",
    fontSize: "17px",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    gap: "20px",
  },
  logo: {
    width: 28,
  },
  title: {
    maxWidth: 350,
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    gap: 10,
    justifyContent: "center",
    flexWrap: window.innerWidth > 1080 ? undefined : "wrap",
    marginBottom: 20,
  },
  card: {
    display: "flex",
    flexDirection: window.innerWidth > 600 ? "row" : "column",
    alignItems: window.innerWidth > 600 ? "unset" : "center",
    padding: 10,
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  cardTitle: {
    fontSize: 20,
  },
  cardDescription: {
    color: "#6B6B6B",
    fontSize: 16,
  },
  cardButtonContainer: {
    display: "flex",
    gap: 5,
    flexDirection: "column",
    margin: "26px 0",
  },
  cardButton: {
    backgroundColor: "#1A8917",
    color: "white",
    display: "flex",
    width: "100%",
  },
  cardFeatureContainer: {
    display: "flex",
    gap: 10,
    margin: 5,
  },
  cardFeatureIcon: {
    color: "green",
  },
  divider: {
    width: 1,
    backgroundColor: "#E5E5E5",
    height: "auto",
    margin: "0 26px",
  },
};

export default Home;
