import React from "react";
// import Viewer from './Viewer.jsx';
import Editor from "./HtmlEditor.jsx";
// import TableOfContents from './TableOfContents.jsx';

const template1 = {
  background: {
    backgroundSize: "cover",
    backgroundColor: "#20493C",
    backgroundImage: "url(./template1.png)",
    width: "100%",
    height: 1123,
    maxWidth: 796,
    margin: "auto",
  },
  ops: [
    {
      attributes: {
        header: 4,
      },
      insert: "\n",
    },
    {
      attributes: {
        color: "#6ac6ac",
        font: "Outfit",
        bold: true,
      },
      insert: "     SEPTEMBER 2030",
    },
    {
      attributes: {
        header: 4,
      },
      insert: "\n",
    },
    {
      insert: "\n\n\n\n\n\n\n\n\n\n\n",
    },
    {
      attributes: {
        color: "#6ac6ac",
        font: "Outfit",
        bold: true,
      },
      insert: "    ",
    },
    {
      attributes: {
        height: "36.17307692307692",
        width: "44",
        color: "#6ac6ac",
        font: "Outfit",
        bold: true,
      },
      insert: {
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/2491px-Logo_of_Twitter.svg.png",
      },
    },
    {
      attributes: {
        color: "#6ac6ac",
        font: "Outfit",
        bold: true,
      },
      insert: "  ",
    },
    {
      attributes: {
        font: "Outfit",
        color: "#6ac6ac",
      },
      insert: "ATOMICA LABS",
    },
    {
      attributes: {
        header: 3,
      },
      insert: "\n",
    },
    {
      insert: "\n",
    },
    {
      attributes: {
        font: "Outfit",
        color: "#ffffff",
        bold: true,
      },
      insert: " Health & Safety",
    },
    {
      attributes: {
        header: 1,
      },
      insert: "\n",
    },
    {
      attributes: {
        font: "Outfit",
        color: "#ffffff",
        bold: true,
      },
      insert: " in Office",
    },
    {
      attributes: {
        header: 1,
      },
      insert: "\n",
    },
    {
      attributes: {
        color: "#6ac6ac",
        font: "Outfit",
      },
      insert: "     Development of Myxeria version 4.0",
    },
    {
      attributes: {
        header: 3,
      },
      insert: "\n",
    },
    {
      insert: "\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n\n",
    },
    {
      attributes: {
        color: "#6ac6ac",
        font: "Outfit",
        bold: true,
      },
      insert:
        "   Presented to:                                                                                                                                                     Presented by:",
    },
    {
      insert: "\n",
    },
    {
      attributes: {
        color: "#6ac6ac",
        font: "Outfit",
        bold: true,
      },
      insert:
        "  Harith Zahid                                                                                           Mike Shinoda",
    },
    {
      attributes: {
        header: 2,
      },
      insert: "\n",
    },
  ],
};

const template2 = {
  background: {
    backgroundSize: "cover",
    backgroundImage: "url(./template1.png)",
    backgroundColor: "white",
    width: "100%",
    height: 1123,
    maxWidth: 796,
    margin: "auto",
  },
  ops: [
    {
      attributes: {
        header: 3,
      },
      insert: "\n",
    },
    {
      attributes: {
        color: "#6ac6ac",
        font: "Outfit",
        bold: true,
      },
      insert: "             ",
    },
    {
      attributes: {
        header: 3,
      },
      insert: "\n",
    },
    {
      attributes: {
        font: "Outfit",
        color: "#6ac6ac",
        bold: true,
      },
      insert: "            ",
    },
    {
      attributes: {
        height: "36.17307692307692",
        width: "44",
        font: "Outfit",
        color: "#6ac6ac",
        bold: true,
      },
      insert: {
        image:
          "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/2491px-Logo_of_Twitter.svg.png",
      },
    },
    {
      attributes: {
        font: "Outfit",
        color: "#6ac6ac",
        bold: true,
      },
      insert: "  ",
    },
    {
      attributes: {
        font: "Outfit",
        color: "#6ac6ac",
      },
      insert: "ATOMICA LABS",
    },
    {
      attributes: {
        header: 3,
      },
      insert: "\n",
    },
    {
      attributes: {
        header: 1,
      },
      insert: "\n",
    },
    {
      insert: "\n\n",
    },
    {
      attributes: {
        font: "Outfit",
        color: "#002966",
        bold: true,
      },
      insert: "    Health & Safety",
    },
    {
      attributes: {
        header: 1,
      },
      insert: "\n",
    },
    {
      attributes: {
        font: "Outfit",
        color: "#002966",
        bold: true,
      },
      insert: "    in Office",
    },
    {
      attributes: {
        header: 1,
      },
      insert: "\n",
    },
    {
      attributes: {
        color: "#6ac6ac",
        font: "Outfit",
      },
      insert: "            Development of Myxeria version 4.0",
    },
    {
      attributes: {
        header: 2,
      },
      insert: "\n",
    },
    {
      insert: "\n",
    },
  ],
};

const Template = () => {
  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "#EBECF0" }}>
      <div style={template2.background}>
        <Editor delta={template2} />
      </div>
    </div>
  );
};

export default Template;
