/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext } from "react";
import { Input } from "antd";
import { useHistory, useParams } from "react-router-dom";

import "./project.css";

import { actionTypes } from "../../actions";
import { post } from "../../utils/fetch";
import { PageContext, usePageActionHandler } from "../../components/Page";
import TabEditableCard from "../../components/TabEditableCard";
import PageActions from "../../components/PageActions";
import TabHeader from "../../components/TabHeader";

export const ProjectContext = createContext(null);

const ProjectDetails = ({
  project,
  projectSources,
  projectCourses,
  handleSetProject,
}) => {
  const { isActionInProgress, setError, withConfirmation } =
    useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const history = useHistory();
  const { projectId } = useParams();

  const deleteProject = () =>
    withConfirmation("deleteProject", async (action) => {
      addAction(action);
      await post(actionTypes[action].api, {
        projectId,
      })
        .then(() => {
          history.push("/studio/projects");
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          removeAction(action);
        });
    });

  const deleteProjectCourses = () =>
    withConfirmation("deleteProjectCourses", async (action) => {
      addAction(action);
      await post(actionTypes[action].api, {
        projectId,
      })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          removeAction(action);
        });
    });

  const updateProjectBasicDetails = async (values) => {
    const action = `updateProjectBasicDetails`;
    addAction(action);
    await post(actionTypes[action].api, {
      projectId,
      name: values.name,
      description: values.description,
    })
      .then(() => {
        handleSetProject({
          name: values.name,
          description: values.description,
        });
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const handleViewCourses = () => {
    history.push(`/studio/project/${projectId}/course/new`);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: 10,
        flexDirection: "column",
        overflow: "auto",
      }}
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <PageActions
          actions={[
            {
              onClick: handleViewCourses,
              loading: isActionInProgress(["deleteProject"]),
              disabled: isActionInProgress([
                "getProject",
                "deleteProject",
                "deleteProjectSources",
                "deleteProjectCourses",
              ]),
              text: "View courses",
            },
            {
              loading: isActionInProgress(["deleteProjectCourses"]),
              onClick: deleteProjectCourses,
              disabled: isActionInProgress([
                "getProject",
                "deleteProject",
                "deleteProjectSources",
                "deleteProjectCourses",
              ]),
              text: "Delete courses",
            },
            {
              onClick: deleteProject,
              loading: isActionInProgress(["deleteProject"]),
              disabled: isActionInProgress([
                "getProject",
                "deleteProject",
                "deleteProjectSources",
                "deleteProjectCourses",
              ]),
              text: "Delete project",
            },
          ]}
        />
      </div>
      <TabHeader label="Project details" />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          height:
            window.innerWidth > 600
              ? "calc(100vh - 180px)"
              : "calc(100vh - 170px)",
          paddingRight: 10,
          gap: 10,
        }}
      >
        <TabEditableCard
          title="Overview"
          editable
          loading={isActionInProgress(["getProject"])}
          onSubmit={updateProjectBasicDetails}
          isSubmitting={isActionInProgress(["updateProjectBasicDetails"])}
          form={{
            initialValues: {
              name: project?.name,
              description: project?.description,
            },
          }}
          data={[
            {
              name: "name",
              label: "Title",
              input: Input,
              loadingRows: 0,
            },
            {
              name: "description",
              label: "Description",
              input: Input.TextArea,
              inputProps: {
                rows: 4,
              },
              loadingRows: 0,
            },
          ]}
        />
        <TabEditableCard
          title="Stats"
          loading={isActionInProgress(["getProject"])}
          form={{
            initialValues: {
              sources: projectSources?.length.toString(),
              courses: projectCourses?.length.toString(),
            },
          }}
          data={[
            {
              name: "courses",
              label: "Courses",
              loadingRows: 0,
            },
          ]}
        />
      </div>
    </div>
  );
};

export default ProjectDetails;
