/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Tabs, Alert, Progress, Spin } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  CrownOutlined,
  DoubleLeftOutlined,
  LoadingOutlined,
  DoubleRightOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { io } from "socket.io-client";

import "./learn.css";
import { post } from "../../utils/fetch";
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from "../../actions";
import TutorTab from "./TutorTab";
import ContentRenderer from "./ContentRenderer";
import CoverEditor from "../template/CoverEditor";
import CustomTag from "../../components/CustomTag";
import axios from "axios";
import { AppContext } from "../../App";
import Show from "../../components/Show";
import DropdownMenu from "../../components/DropdownMenu";
import { convertToLocaleTime } from "../../utils/date";

const defaultSidebarSize =
  window.innerWidth > 900 ? (40 / 100) * window.innerWidth : 20;

const Learn = () => {
  const { appState, appActions } = useContext(AppContext);
  const { getAvailableFeatures } = appActions;
  const { user: userData } = appState;
  const { setError, isActionInProgress } = useContext(PageContext);
  const { courseId, submoduleId } = useParams();
  const { addAction, removeAction } = usePageActionHandler();
  const contentRef = useRef();

  const [modules, setModules] = useState(null);
  const [keypoints, setKeypoints] = useState(null);
  const [viewedContent, setViewedContent] = useState(null);
  const [chatSession, setChatSession] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [sidebarSize, setSidebarSize] = useState(defaultSidebarSize);
  const [course, setCourse] = useState(null);
  const [showCover, setShowCover] = useState(false);
  const [coverTemplates, setCoverTemplates] = useState(null);
  const [themes, setThemes] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [currentCoverDelta, setCurrentCoverDelta] = useState("");
  const [currentCoverBackground, setCurrentCoverBackground] = useState({});
  const [activeTab, setActiveTab] = useState(null);
  const [downloadPdfStatus, setDownloadPdfStatus] = useState(null);
  const [downloadPdfUrl, setDownloadPdfUrl] = useState(null);
  const [lastGeneratePdfDate, setLastGeneratePdfDate] = useState(null);
  const [hideTutorSaveTokenMessage, setHideTutorSaveTokenMessage] = useState(
    localStorage.getItem("hideTutorSaveTokenMessage"),
  );
  const availableFeatures = getAvailableFeatures(course?.plan_type_id);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    if (courseId && userData) {
      socket.emit("subscribeToStatusUpdate", `${courseId}-${userData?.id}`);
      socket.on("statusUpdate", (socketData) => {
        const { data } = socketData;
        if (data.action === "generatePdf") {
          if (data.status) {
            setDownloadPdfUrl(null);
            setDownloadPdfStatus(data.status);
          }
          if (data.downloadUrl) {
            setDownloadPdfUrl(data.downloadUrl);
            setLastGeneratePdfDate(data.updatedAt);
          }
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [courseId, userData]);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [viewedContent?.id]);

  useEffect(() => {
    initApiCalls();
  }, []);

  useEffect(() => {
    if (course?.id && submoduleId) {
      getKeypointContent(submoduleId);
    }
  }, [course?.id, submoduleId]);

  const initApiCalls = async () => {
    const initialCourse = await getCourse();
    await getCoverTemplates(initialCourse.course?.template_theme_id);
    if (!submoduleId) {
      setShowCover(true);
      setActiveTab(window.innerWidth > 500 ? "cover" : "curriculum");
    } else {
      setActiveTab("curriculum");
    }
  };

  const getCourse = async (cb) => {
    const action = `getCourse`;
    addAction(action);
    return await post(actionTypes[action].api, {
      courseId,
    })
      .then((res) => {
        setCourse(res.data.course);
        setModules(res.data.module);
        setSelectedTemplate(res.data.course.cover_template_id);
        setSelectedTheme(res.data.course.template_theme_id);
        setCurrentCoverDelta(res.data.course.cover_delta);
        setDownloadPdfUrl(res.data.course.download_url);
        setLastGeneratePdfDate(res.data.course.last_updated_download_url);
        const newKeypoint = res.data.keypoint.reduce((prev, curr) => {
          return {
            ...prev,
            [curr.module_id]: [
              ...(prev[curr.module_id] || []),
              {
                title: curr.title,
                id: curr.id,
              },
            ],
          };
        }, {});
        setKeypoints(newKeypoint);
        return res.data;
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const getCoverTemplates = async (themeId) => {
    const action = `getCoverTemplates`;
    addAction(action);
    await post(actionTypes[action].api)
      .then((res) => {
        setCoverTemplates(res.data.templates);
        setThemes(res.data.themes);
        setCurrentCoverBackground(
          res.data.themes.find((item) => item.id === themeId).background_styles,
        );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const updateCourseCover = async (delta) => {
    const action = `updateCourseCover`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId,
      templateThemeId: selectedTheme,
      delta,
    })
      .then((res) => {
        const updatedCourse = {
          ...course,
          template_theme_id: selectedTheme,
          cover_template_id: selectedTemplate,
          cover_delta: delta,
        };
        setCourse(updatedCourse);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const uploadCoverImage = async (file) => {
    const filename = encodeURIComponent(file.name);
    const action = "createUploadImgPresigned";
    try {
      addAction(action);
      const result = await post(actionTypes[action].api, {
        filename,
        courseId,
      });
      const { url, fields } = result;
      const formData = new FormData();
      Object.entries({ ...fields, file }).forEach(([key, value]) => {
        formData.append(key, value);
      });
      await axios.post(url, formData);
      removeAction(action);
      return `${url}${fields.key}`;
    } catch (error) {
      setError(error);
      removeAction(action);
    }
  };

  const getKeypointContent = async (keypointId) => {
    const action = `getSubmoduleContent`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId,
      keypointId,
    })
      .then((res) => {
        if (!res.data.content && !res?.data?.delta) {
          setError({ message: "Content not exist." });
        }
        setViewedContent({
          delta: res?.data?.delta,
          content: res?.data?.content,
          id: keypointId,
        });
        setChatSession(res?.data?.chatSession || []);
        setChatHistory(res?.data?.chatHistory || []);
        if (window.innerWidth < 900) {
          setSidebarSize(defaultSidebarSize);
        }
        setShowCover(false);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const downloadPdf = async () => {
    if (!availableFeatures["download-course"]) {
      setError("Upgrade to access this feature.");
      return;
    }
    const action = `downloadCoursePdf`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId,
    })
      .then(() => {
        setActiveTab("download");
        setDownloadPdfStatus({
          index: 0,
          totalUrl: 100,
        });
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const templateWidth = sidebarSize / 3 - 25 > 300 ? 300 : sidebarSize / 3 - 25;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            backgroundColor: showCover ? "#EBECF0" : undefined,
            display: window.innerWidth - 25 <= sidebarSize ? "none" : undefined,
            width: `calc(100vw - ${sidebarSize}px)`,
            padding: window.innerWidth > 900 ? "20px 80px 80px 80px" : "40px",
            height: "calc(100vh - 12px)",
            overflowY: "scroll",
          }}
        >
          {showCover ? (
            <CoverEditor
              backgroundStyles={currentCoverBackground}
              delta={currentCoverDelta}
              onSave={updateCourseCover}
              onUploadImg={async (file) => {
                return await uploadCoverImage(file);
              }}
              selected={selectedTheme === course?.template_theme_id}
            />
          ) : (
            <Show show={viewedContent}>
              <div
                style={{
                  maxWidth: 800,
                  margin: "auto",
                  width: `calc(100vw - ${sidebarSize})`,
                }}
              >
                <ContentRenderer viewedContent={viewedContent} />
              </div>
            </Show>
          )}
        </div>
      </div>
      <div
        style={{
          position: "relative",
          marginLeft: "auto",
          height: "calc(100vh - 12px)",
          width: sidebarSize,
          boxShadow: "11px 11px 20px 8px rgba(238, 237, 237, 0.75)",
          padding: 0,
        }}
      >
        <Tabs
          type="card"
          destroyInactiveTabPane
          activeKey={activeTab}
          onTabClick={(val) => {
            if (val === "cover") {
              setShowCover(true);
            }

            if (val === "curriculum" && viewedContent) {
              setShowCover(false);
            }
            setActiveTab(val);
          }}
          tabBarExtraContent={{
            right: (
              <>
                {userData?.id === course?.user_id && (
                  <div
                    style={{
                      display: "flex",
                      gap: 7,
                      alignItems: "flex-end",
                      margin: "0 10px 0 10px",
                    }}
                  >
                    <DropdownMenu
                      btnType="ghost"
                      menuItems={[
                        {
                          label: "Overview",
                          key: "overview",
                          path: `/project/${course?.project_id}/overview/${course?.id}`,
                        },
                        {
                          label: "Customize",
                          key: "editor",
                          path: `/studio/project/${course?.project_id}/course/${courseId}`,
                        },
                      ]}
                    />
                  </div>
                )}
              </>
            ),
          }}
          items={[
            window.innerWidth > 500
              ? {
                label: "Cover",
                key: "cover",
                children: (
                  <div
                    style={{
                      display: "flex",
                      gap: 20,
                      flexDirection: "column",
                      padding: "10px 20px 10px 30px",
                      height: "calc(100vh - 67px)",
                      overflow: "auto",
                    }}
                  >
                    <div>
                      <h2>Templates</h2>
                      <div
                        style={{ display: "flex", gap: 5, flexWrap: "wrap" }}
                      >
                        {coverTemplates?.map((template) => (
                          <div
                            key={template.id}
                            onClick={() => {
                              const foundSelectedThemeId =
                                course.cover_template_id === template.id
                                  ? course.template_theme_id
                                  : coverTemplates.find(
                                    (item) => item.id === template.id,
                                  ).default_theme_id;
                              setSelectedTemplate(template.id);
                              setSelectedTheme(foundSelectedThemeId);
                              setCurrentCoverDelta(
                                template.id === course.cover_template_id
                                  ? course.cover_delta
                                  : template.delta,
                              );
                              setCurrentCoverBackground(
                                themes.find(
                                  (item) => item.id === foundSelectedThemeId,
                                ).background_styles,
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              width: templateWidth,
                              height: "100%",
                              minHeight: templateWidth * 1.4,
                              backgroundImage: `url("${themes.find((item) => item.id === template.default_theme_id).thumbnail_img}")`,
                              backgroundSize: "cover",
                              border:
                                selectedTemplate === template.id
                                  ? "1px solid black"
                                  : "1px solid lightgrey",
                              borderRadius: 10,
                            }}
                          ></div>
                        ))}
                      </div>
                    </div>
                    <div>
                      <h3>Themes</h3>
                      <div
                        style={{ display: "flex", gap: 5, flexWrap: "wrap" }}
                      >
                        {themes
                          .filter(
                            (item) => item.template_id === selectedTemplate,
                          )
                          .map((theme) => (
                            <div
                              key={theme.id}
                              onClick={() => {
                                setSelectedTheme(theme.id);
                                setCurrentCoverBackground(
                                  theme.background_styles,
                                );
                              }}
                              style={{
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: templateWidth,
                                height: "100%",
                                minHeight: templateWidth * 1.4,
                                backgroundImage: `url("${theme.thumbnail_img}")`,
                                backgroundSize: "cover",
                                border:
                                  selectedTheme === theme.id
                                    ? "1px solid black"
                                    : "1px solid lightgrey",
                                borderRadius: 10,
                              }}
                            >
                              {selectedTheme === theme.id && (
                                <CustomTag color="black">Selected</CustomTag>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ),
              }
              : {},
            {
              label: "Table of Contents",
              key: "curriculum",
              children: (
                <div
                  style={{
                    display: sidebarSize > 200 ? undefined : "none",
                    height: "calc(100vh - 67px)",
                    overflow: "auto",
                    padding: "20px 20px 10px 30px",
                  }}
                >
                  {modules?.map((item) => {
                    return (
                      <div style={{ minWidth: 250 }}>
                        <h4 style={{ fontSize: 16 }}>{item.title}</h4>
                        <ul>
                          {keypoints[item.id]?.map((point) => (
                            <li
                              className="learn-keypoints"
                              style={{
                                cursor: "pointer",
                                color:
                                  Number(viewedContent?.id) === point.id
                                    ? "blue"
                                    : undefined,
                              }}
                              onClick={() => {
                                setViewedContent(null);
                                getKeypointContent(point.id, point.title);
                              }}
                            >
                              {point.title}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  })}
                </div>
              ),
            },
            {
              label: "Assistant",
              key: "chat",
              disabled: !viewedContent?.id,
              children: (
                <div
                  style={{ display: sidebarSize === 15 ? "none" : undefined }}
                >
                  {hideTutorSaveTokenMessage ? (
                    <TutorTab
                      viewedContent={viewedContent}
                      chatSession={chatSession}
                      setChatSession={setChatSession}
                      chatHistory={chatHistory}
                      setChatHistory={setChatHistory}
                      course={course}
                    />
                  ) : (
                    <div style={{ margin: 10 }}>
                      <Alert
                        message={<b>Tips</b>}
                        description={
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 20,
                            }}
                          >
                            <div>
                              Save your tokens by starting a new session every
                              time you want to start a new conversation topic.
                            </div>
                            <Button
                              style={{ alignSelf: "end" }}
                              onClick={() => {
                                localStorage.setItem(
                                  "hideTutorSaveTokenMessage",
                                  true,
                                );
                                setHideTutorSaveTokenMessage(true);
                              }}
                            >
                              Start conversation
                            </Button>
                          </div>
                        }
                        type="info"
                      />
                    </div>
                  )}
                </div>
              ),
            },
            {
              label: "Download",
              key: "download",
              children: (
                <div
                  style={{
                    display: "flex",
                    gap: 20,
                    flexDirection: "column",
                    margin: "10px 20px 10px 25px",
                  }}
                >
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    {downloadPdfStatus?.totalUrl &&
                      downloadPdfStatus?.index + 1 <
                      downloadPdfStatus?.totalUrl && (
                        <Spin
                          indicator={
                            <LoadingOutlined
                              style={{
                                fontSize: 16,
                              }}
                              spin
                            />
                          }
                        />
                      )}
                    <Progress
                      style={{ margin: "-8px 0 0 0", height: 14 }}
                      strokeColor="#0390ff"
                      size="small"
                      showInfo
                      percent={Math.round(
                        downloadPdfUrl && downloadPdfStatus
                          ? 100
                          : downloadPdfStatus
                            ? (downloadPdfStatus.index /
                              downloadPdfStatus.totalUrl) *
                            100
                            : 0,
                      )}
                    />
                  </div>
                  <div
                    style={{ display: "flex", flexDirection: "column", gap: 5 }}
                  >
                    {downloadPdfUrl && lastGeneratePdfDate && (
                      <div style={{ display: "flex", gap: 10 }}>
                        <span>Last generated: </span>
                        <span>
                          {convertToLocaleTime(
                            course.last_updated_download_url,
                          )}
                        </span>
                      </div>
                    )}
                    <Button
                      disabled={isActionInProgress()}
                      onClick={() => {
                        setDownloadPdfUrl(null);
                        downloadPdf();
                      }}
                      icon={
                        !availableFeatures["download-course"] && (
                          <CrownOutlined
                            style={{ fontSize: 11, color: "gold" }}
                          />
                        )
                      }
                    >
                      <span style={{ fontFamily: "Outfit" }}>Generate PDF</span>
                    </Button>
                    <Button
                      disabled={!downloadPdfUrl}
                      onClick={() => {
                        window.open(downloadPdfUrl);
                      }}
                    >
                      View & download
                    </Button>
                  </div>
                </div>
              ),
            },
          ]}
        />
        <Button
          onClick={() => {
            if (window.innerWidth > 900) {
              setSidebarSize(
                sidebarSize === window.innerWidth - 20
                  ? (40 / 100) * window.innerWidth
                  : 15,
              );
            } else {
              setSidebarSize(15);
            }
          }}
          style={{
            position: "absolute",
            left: window.innerWidth > 900 ? -23 : -18,
            top: "calc(50vh - 66px)",
          }}
          icon={<DoubleRightOutlined />}
        />
        <Button
          onClick={() => {
            if (window.innerWidth > 900) {
              setSidebarSize(
                sidebarSize === 15
                  ? (40 / 100) * window.innerWidth
                  : window.innerWidth - 20,
              );
            } else {
              setSidebarSize(window.innerWidth - 20);
            }
          }}
          style={{
            position: "absolute",
            left: window.innerWidth > 900 ? -23 : -18,
            top: "calc(50vh - 30px)",
          }}
          icon={<DoubleLeftOutlined />}
        />
      </div>
    </div>
  );
};

export default Learn;
