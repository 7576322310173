import React from "react";
import { useQuill } from "react-quilljs";
import { ImageFormats } from "@xeger/quill-image-formats";

import "quill/dist/quill.bubble.css";
import "./coverEditor.css";

const CoverViewer = ({ delta }) => {
  const { quill, quillRef, Quill } = useQuill({
    theme: "bubble",
    modules: {
      imageFormats: {},
      clipboard: {
        matchVisual: false,
      },
    },
    formats: [
      "background",
      "bold",
      "color",
      "code",
      "italic",
      "link",
      "size",
      "strike",
      "script",
      "underline",
      "blockquote",
      "header",
      "indent",
      "list",
      "align",
      "direction",
      "code-block",
      "formula",
      "image",
      "height",
      "width",
      "float",
    ],
  });

  if (Quill && !quill) {
    const block = Quill.import("blots/block");
    block.tagName = "div";
    Quill.register(block);
    Quill.register("modules/imageFormats", ImageFormats);
  }

  React.useEffect(() => {
    if (quill) {
      quill.setContents(delta);
      quill.enable(false);
    }
  }, [quill, delta]);

  return (
    <div className="cover-editor">
      <div ref={quillRef} />
    </div>
  );
};

export default CoverViewer;
