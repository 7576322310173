import { Card } from "antd";

const TabHeader = ({ label }) => {
  return (
    <Card style={{ border: 0, backgroundColor: "#c3e3ff", padding: 4 }}>
      <h2 style={{ fontFamily: "Outfit" }}>{label}</h2>
    </Card>
  );
};

export default TabHeader;
