/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import { PageContext, usePageActionHandler } from "../../components/Page";
import CustomizeCoursePage from "./Customize";
import Show from "../../components/Show";

export const CourseContext = createContext(null);

const ProjectCourse = () => {
  const { setError } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const { contentId } = useParams();
  const [courseData, setCourseData] = useState(null);

  useEffect(() => {
    getCourseDetails();
  }, []);

  const getCourseDetails = async () => {
    const action = "getCourseDetails";
    addAction(action, actionTypes[action].fetchMessage);
    return await post(actionTypes[action].api, {
      courseId: contentId,
    })
      .then((res) => {
        setCourseData(res.data);
        return res.data;
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const getSubmodules = (moduleIndex) => {
    return courseData?.course?.course_module[moduleIndex].keypoints;
  };

  const updateSubmoduleStatus = (moduleIndex, submoduleIndex, status) => {
    const newCourseData = JSON.parse(JSON.stringify(courseData));
    newCourseData.course.course_module[moduleIndex].keypoints[submoduleIndex] =
      {
        ...newCourseData.course.course_module[moduleIndex].keypoints[
          submoduleIndex
        ],
        status_id: status,
      };
    setCourseData(newCourseData);
  };

  const updateModuleStatus = (moduleIndex, status) => {
    const newCourseData = JSON.parse(JSON.stringify(courseData));
    newCourseData.course.course_module[moduleIndex] = {
      ...newCourseData.course.course_module[moduleIndex],
      status_id: status,
    };
    setCourseData(newCourseData);
  };

  const updateCourseStatus = (status) => {
    setCourseData({
      ...courseData,
      course: {
        ...courseData.course,
        status_v2_id: status,
      },
    });
  };

  const handleUpdateCourseModuleSuccess = (moduleId, title, description) => {
    const newCourseData = JSON.parse(JSON.stringify(courseData));
    const moduleIndex = newCourseData.course.course_module.findIndex(
      (item) => item.id === moduleId,
    );
    newCourseData.course.course_module[moduleIndex] = {
      ...newCourseData.course.course_module[moduleIndex],
      title,
      description,
    };
    setCourseData(newCourseData);
  };

  const handleUpdateSubmodulesSuccess = (
    moduleIndex,
    submoduleIndex,
    title,
    description,
  ) => {
    const newCourseData = JSON.parse(JSON.stringify(courseData));
    newCourseData.course.course_module[moduleIndex].keypoints[submoduleIndex] =
      {
        ...newCourseData.course.course_module[moduleIndex].keypoints[
          submoduleIndex
        ],
        title,
        description,
        status_id: null,
      };
    setCourseData(newCourseData);
  };

  const handleSortModules = async (newModules, deletedModule) => {
    const newCourseData = { ...courseData };
    newCourseData.course.course_module = [...newModules];
    const action = "sortCourseModules";
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: contentId,
      sortedModules: newModules.map((item) => item.id),
      deletedModuleId: deletedModule?.id || undefined,
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
        setCourseData(newCourseData);
      });
  };

  const handleSortSubmodules = async (
    moduleIndex,
    newSubmodules,
    deletedSubmodule,
  ) => {
    const newCourseData = { ...courseData };
    newCourseData.course.course_module[moduleIndex].keypoints = [
      ...newSubmodules,
    ];
    const action = "sortCourseSubmodules";
    addAction(action);
    await post(actionTypes[action].api, {
      moduleId: newCourseData.course.course_module[moduleIndex].id,
      courseId: contentId,
      sortedSubmodules: newSubmodules.map((item) => item.id),
      deletedSubmoduleId: deletedSubmodule?.id || undefined,
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
        setCourseData(newCourseData);
      });
  };

  return (
    <CourseContext.Provider
      value={{
        courseData,
        getSubmodules,
        handleSortModules,
        handleSortSubmodules,
        getCourseDetails,
        handleUpdateCourseModuleSuccess,
        handleUpdateSubmodulesSuccess,
        updateSubmoduleStatus,
        updateModuleStatus,
        updateCourseStatus,
        setActiveTabKey: () => {},
      }}
    >
      <Show show={courseData?.course?.id}>
        <CustomizeCoursePage />
      </Show>
    </CourseContext.Provider>
  );
};

export default ProjectCourse;
