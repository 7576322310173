/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

import useScrollToTop from "../../utils/useScrollToTop";
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from "../../actions";
import { post } from "../../utils/fetch";
import { Button, Card, Input } from "antd";
import MainMenu from "../../components/MainMenu";
import { convertToLocaleTime } from "../../utils/date";
import Markdown from "../../components/Markdown";
import CodeBlock from "../../components/CodeBlock";

const AdminPage = () => {
  useScrollToTop();
  const { setError } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [activity, setActivity] = useState([]);
  const [currentItemView, setCurrentItemView] = useState(null);
  const [isPlainResponse, setIsPlainResponse] = useState(false);
  const pageRef = useRef();
  const filterByColumnNameRef = useRef();
  const filterByColumnValueRef = useRef();

  useEffect(() => {
    getUserActivity();
  }, []);

  const getUserActivity = async (
    page,
    filterByColumnName,
    filterByColumnValue,
  ) => {
    const action = "getPrompts";
    addAction(action, actionTypes[action].fetchMessage);
    await post(actionTypes[action].api, {
      sortBy: {
        name: "created_date",
        ascending: false,
      },
      filterBy: {
        column: filterByColumnName || "name",
        value: filterByColumnValue || undefined,
      },
      pageSize: 10,
      pageNumber: page || 1,
    })
      .then((res) => {
        setActivity(res.result.data);
      })
      .catch((err) => {
        setError(err);
      });
    removeAction(action);
  };

  const onClickPrompt = (item) => {
    if (item.id === currentItemView?.id) {
      setCurrentItemView(null);
      return;
    }
    setCurrentItemView(item);
  };

  const onChangePage = async () => {
    await getUserActivity(
      pageRef.current?.input?.value,
      filterByColumnNameRef.current?.input?.value,
      filterByColumnValueRef.current?.input?.value,
    );
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 10, padding: 10 }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link to="/home">
          <img style={{ width: 22 }} alt="chat2course" src="/logo.png" />
        </Link>
        <MainMenu />
      </div>
      <Card style={{ border: 0, backgroundColor: "#c3e3ff", padding: 4 }}>
        <h2 style={{ fontFamily: "Outfit" }}>Prompts</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 5,
            alignItems: "baseline",
          }}
        >
          <Input placeholder="Page" ref={pageRef} />
          <Input placeholder="Filter name" ref={filterByColumnNameRef} />
          <Input placeholder="Filter value" ref={filterByColumnValueRef} />
          <Button onClick={onChangePage}>Search</Button>
          <Button
            onClick={() => {
              onChangePage();
            }}
          >
            Refresh
          </Button>
        </div>
      </Card>
      <div
        style={{
          overflow: "auto",
          height: "calc(100vh - 220px)",
          display: "flex",
          gap: 5,
          flexDirection: "column",
        }}
      >
        {activity.map((item) => {
          return (
            <Card size="small" id={item.id}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection:
                    window.innerWidth > 600 ? "row" : "column-reverse",
                  gap: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    flexDirection: window.innerWidth > 600 ? "row" : "column",
                  }}
                >
                  <span style={{ textAlign: "right" }}>{item.name}</span>
                  <span style={{ textAlign: "right" }}>{item.user_id}</span>
                  <span style={{ textAlign: "right" }}>
                    {item.metadata.total_tokens}
                  </span>
                  <span style={{ textAlign: "right" }}>{item.model}</span>
                  <Button
                    size="small"
                    onClick={() => {
                      onClickPrompt(item);
                    }}
                  >
                    Toggle view
                  </Button>
                </div>
                <span style={{ textAlign: "right" }}>
                  {convertToLocaleTime(item.created_date)}
                </span>
              </div>
              {currentItemView?.id === item.id && (
                <div style={{ marginTop: 20, display: "flex", gap: 10 }}>
                  <Card title="Prompt" size="small" style={{ width: "70%" }}>
                    {currentItemView.prompt?.map((item) => {
                      return (
                        <div>
                          <div style={{ marginBottom: 5, fontWeight: 700 }}>
                            {item.role}
                          </div>
                          <div
                            style={{
                              marginBottom: 5,
                              whiteSpaceCollapse: "preserve-breaks",
                            }}
                          >
                            {item.content}
                          </div>
                        </div>
                      );
                    })}
                  </Card>
                  <Card
                    title={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>Response</span>
                        <Button
                          size="small"
                          onClick={() => {
                            setIsPlainResponse(!isPlainResponse);
                          }}
                        >
                          Toggle format
                        </Button>
                      </div>
                    }
                    size="small"
                    style={{ width: "30%", backgroundColor: "aliceblue" }}
                  >
                    {isPlainResponse ? (
                      <div>{currentItemView.response}</div>
                    ) : (
                      <Markdown
                        className="chat-markdown"
                        components={{ code: CodeBlock }}
                      >
                        {currentItemView.response}
                      </Markdown>
                    )}
                  </Card>
                </div>
              )}
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default AdminPage;
