import React from "react";
import { useQuill } from "react-quilljs";
import BlotFormatter from "quill-blot-formatter";

import "quill/dist/quill.bubble.css";
// import './styles.css'

const supportedFontSize = [
  "false",
  "10px",
  "20px",
  "30px",
  "40px",
  "50px",
  "60px",
  "70px",
  "80px",
  "90px",
];

const Editor = ({ delta, html }) => {
  const { quill, quillRef, Quill } = useQuill({
    theme: "bubble",
    modules: {
      blotFormatter: {},
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["link", "image"],
        [{ color: [] }, { background: [] }],
        // [{ 'font': ['SourceSerifPro', 'Outfit'] }],
        // [{ size: supportedFontSize }],
        ["clean"],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    formats: [
      "background",
      "bold",
      "color",
      "font",
      "code",
      "italic",
      "link",
      "size",
      "strike",
      "script",
      "underline",
      "blockquote",
      "header",
      "indent",
      "list",
      "align",
      "direction",
      "code-block",
      "formula",
      "image",
      "height",
      "width",
    ],
  });

  if (Quill && !quill) {
    const block = Quill.import("blots/block");
    block.tagName = "div";
    Quill.register(block);

    const fontStyle = Quill.import("formats/font");
    fontStyle.whitelist = ["Outfit", "SourceSerifPro"];
    Quill.register(fontStyle, true);

    Quill.register("modules/blotFormatter", BlotFormatter);

    const fontSize = Quill.import("attributors/style/size");
    fontSize.whitelist = supportedFontSize;
    Quill.register(fontSize, true);
  }

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  const saveToServer = async (file) => {
    const body = new FormData();
    body.append("file", file);

    // const res = await fetch('Your Image Server URL', { method: 'POST', body });
    // insertToEditor(res.uploadedImageUrl);
    insertToEditor(
      "https://upload.wikimedia.org/wikipedia/commons/thumb/6/6f/Logo_of_Twitter.svg/2491px-Logo_of_Twitter.svg.png",
    );
  };

  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  // React.useEffect(() => {
  //   if (quill) {
  //     quill.on('text-change', (delta, oldContents) => {
  //       let currrentContents = quill.getContents();
  //       console.log('Text change!');
  //       console.log({ delta: delta });
  //       console.log({ text: quill.getText() });
  //       console.log({ content: quill.getContents() });
  //       console.log({ html: quill.root.innerHTML });
  //       console.log({ diff: currrentContents.diff(oldContents) });
  //     });
  //   }
  // }, [quill, Quill]);

  React.useEffect(() => {
    if (quill) {
      if (!delta) {
        quill.clipboard.dangerouslyPasteHTML(html);
      } else {
        quill.setContents(delta);
      }
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
    }
  }, [quill]);

  return <div ref={quillRef} />;
};

export default Editor;
