/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Form, Space } from "antd";
import { useEffect, useState } from "react";
import { EditOutlined, CloseOutlined } from "@ant-design/icons";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import Show from "./Show";

const SubmitButton = ({ form, submitText, isSubmitting }) => {
  const [submittable, setSubmittable] = useState(false);

  const values = Form.useWatch([], form);
  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(
        () => {
          setSubmittable(true);
        },
        () => {
          setSubmittable(false);
        },
      );
  }, [values]);

  return (
    <Button
      loading={isSubmitting}
      disabled={isSubmitting || !submittable}
      type="primary"
      htmlType="submit"
    >
      {submitText || "Submit"}
    </Button>
  );
};

const TabEditableCard = ({
  title,
  style = {},
  isSubmitting,
  submitText,
  onSubmit,
  formOnly,
  markdown,
  editable,
  rightItems = [],
  data,
  form: formProps,
  loading,
  allowClose,
  onClose = () => {},
  showHeader = true,
  ...props
}) => {
  const [form] = Form.useForm();
  const [isEdit, setIsEdit] = useState(props.isEdit);

  const onClickEdit = () => {
    setIsEdit(!isEdit);
  };

  return (
    <div style={{ width: "100%", ...style }}>
      {showHeader && (
        <div
          style={{ display: "flex", justifyContent: "space-between", gap: 20 }}
        >
          <h2>{title}</h2>
          <div style={{ display: "flex", gap: 5 }}>
            {editable && !formOnly && (
              <Button
                disabled={loading}
                onClick={onClickEdit}
                type="ghost"
                icon={isEdit ? <CloseOutlined /> : <EditOutlined />}
              />
            )}
            {allowClose && (
              <Button
                disabled={loading}
                onClick={onClose}
                type="ghost"
                icon={<CloseOutlined />}
              />
            )}
            {rightItems.map((component) => component)}
          </div>
        </div>
      )}
      <div style={{ marginTop: 10 }}>
        {!isEdit &&
          !formOnly &&
          data.map((item) => {
            return (
              <div>
                <h3>{item.label}</h3>
                <Show rows={item.loadingRows} show={!loading}>
                  {markdown ? (
                    <ReactMarkdown
                      children={
                        formProps.initialValues?.[item.name] ||
                        item.emptyMessage
                      }
                    />
                  ) : (
                    <p>
                      {formProps.initialValues?.[item.name] ||
                        item.emptyMessage}
                    </p>
                  )}
                </Show>
              </div>
            );
          })}
      </div>
      <div>
        {(isEdit || formOnly) && (
          <Form layout="vertical" onFinish={onSubmit} {...formProps}>
            {data.map(({ name, label, input: Input, inputProps = {} }) => (
              <div>
                <Form.Item name={name} label={label}>
                  <Input {...inputProps} />
                </Form.Item>
              </div>
            ))}
            <Form.Item>
              <Space>
                <SubmitButton
                  isSubmitting={isSubmitting}
                  submitText={submitText}
                  form={form}
                />
                <Button htmlType="reset">Reset</Button>
              </Space>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

const Main = ({ unstyle, ...props }) => {
  if (!unstyle) {
    return (
      <Card style={{ ...props.style }}>
        <TabEditableCard {...props} />
      </Card>
    );
  }

  return <TabEditableCard {...props} />;
};

export default Main;
