import { Button, Card, Tooltip } from "antd";
import "./pricing.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useScrollToTop from "../../utils/useScrollToTop";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";

import React, { useContext, useState } from "react";
import { post } from "../../utils/fetch";
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from "../../actions";
import PriceSlider from "./PriceSlider";
import TokenInfo from "./TokenInfoTooltip";
import { AppContext } from "../../App";

const plans = [
  {
    id: "v1_sub_starter",
    title: "Pro Plan",
    price: "20 / month",
    desc: "Transform your expertise into professional courses instantly with Chat2Course PRO, the ultimate AI course creation solution for educators and consultants.",
    features1: [
      "Unlimited course creation",
      "Unlimited course storage",
      "Download course as PDF",
      "Fully customized course structure & content",
      "Cover template selection",
    ],
    purchaseBtnText: "Subscribe",
  },
];

const Pricing = () => {
  useScrollToTop();
  const { setError } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const history = useHistory();
  const { appState } = React.useContext(AppContext);
  const isProPlanSubscriber = appState?.selfApiPlan?.plan_id === "v3_pro_plan";
  const userEmail = localStorage.getItem("email");
  const [decimalInputValue, setDecimalInputValue] = useState(6);

  const handleGenerateBuyTokenPaymentLink = async (productKey, amount) => {
    const action = "createTokenPaymentLink";
    addAction(action);
    await post(actionTypes[action].api, {
      productKey,
      amount,
      url: window.location.protocol + "//" + window.location.host,
    })
      .then((res) => {
        localStorage.removeItem("openAiApiKey");
        window.location.replace(
          `${res.data.paymentLink}?prefilled_email=${encodeURIComponent(userEmail)}`,
        );
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage: `url("https://chat2stats.imgix.net/Untitled%20design%20(11).png")`,
      }}
    >
      <div style={styles.container}>
        <Link to="/account">
          <img style={styles.logo} alt="chat2course" src="/logo.png" />
        </Link>
        <h1 style={styles.title}>
          Supercharge Your Course Development Workflow
        </h1>
      </div>
      <div
        style={{
          padding: 20,
          marginBottom: 80,
          display: "flex",
          gap: 20,
          flexDirection: window.innerWidth > 1080 ? "row" : "column",
          maxWidth: window.innerWidth > 1080 ? 1200 : 600,
          alignItems: "center",
          margin: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 20,
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            margin: window.innerWidth > 1080 ? "0 -40px 0 40px" : 0,
          }}
        >
          <div style={styles.buttonContainer}>
            {plans.map((plan) => (
              <Card hoverable key={plan.id} style={{ width: "100%" }}>
                <div style={styles.card}>
                  <div style={{ maxWidth: 400 }}>
                    <div style={styles.cardHeader}>
                      <h2>{plan.title}</h2>
                      <span style={styles.cardTitle}>${plan.price}</span>
                    </div>
                    <p style={styles.cardDescription}>{plan.desc}</p>
                    <div style={styles.cardButtonContainer}>
                      <Button
                        size="large"
                        disabled={isProPlanSubscriber}
                        onClick={() => {
                          if (localStorage.getItem("accessToken")) {
                            handleGenerateBuyTokenPaymentLink("v3_pro_plan");
                          } else {
                            history.push("/");
                          }
                        }}
                        style={{
                          ...styles.cardButton,
                          backgroundColor: isProPlanSubscriber
                            ? "#1677ff"
                            : "#1A8917",
                        }}
                      >
                        <span style={{ margin: "auto" }}>
                          {isProPlanSubscriber
                            ? "Active"
                            : plan.purchaseBtnText}
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Card>
            ))}
          </div>
          <Card
            hoverable
            style={{ width: "100%", margin: "auto", padding: 20 }}
          >
            <div>
              <h2 style={{ display: "flex", gap: 10, alignItems: "center" }}>
                Purchase tokens{" "}
                <Tooltip
                  trigger="click"
                  overlayInnerStyle={{ maxWidth: 400, width: "100%" }}
                  title={TokenInfo}
                >
                  <InfoCircleOutlined style={{ fontSize: 16 }} />
                </Tooltip>
              </h2>
              {!isProPlanSubscriber && (
                <p>50% Off with active subscription to Pro Plan</p>
              )}
              {appState?.selfApiPlan?.plan_id && (
                <PriceSlider
                  pricePer100kTokens={isProPlanSubscriber ? 3 : 6}
                  decimalInputValue={decimalInputValue}
                  setDecimalInputValue={setDecimalInputValue}
                  handleGenerateBuyTokenPaymentLink={() => {
                    if (localStorage.getItem("accessToken")) {
                      handleGenerateBuyTokenPaymentLink(
                        "token_v2",
                        decimalInputValue * 100,
                      );
                    } else {
                      history.push("/signin");
                    }
                  }}
                />
              )}
            </div>
          </Card>
        </div>
        {plans.map((plan) => (
          <Card
            hoverable
            key={plan.id}
            style={{
              width: "100%",
              padding: 20,
              maxWidth: window.innerWidth > 1080 ? 400 : "unset",
            }}
          >
            <h2>Features</h2>
            <div style={{ width: "100%", display: "flex", fontSize: 14 }}>
              <div style={{ width: "100%" }}>
                {plan.features1.map((feature) => (
                  <div key={feature.id} style={styles.cardFeatureContainer}>
                    <CheckOutlined style={styles.cardFeatureIcon} />
                    <span>{feature}</span>
                  </div>
                ))}
                <div style={styles.cardFeatureContainer}>
                  <CheckOutlined style={styles.cardFeatureIcon} />
                  <span>
                    500k AI tokens/month <b>(PRO Plan)</b>
                  </span>
                  <Tooltip
                    trigger="click"
                    overlayInnerStyle={{ maxWidth: 400, width: "100%" }}
                    title={TokenInfo}
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
                <div style={styles.cardFeatureContainer}>
                  <CheckOutlined style={styles.cardFeatureIcon} />
                  <span>
                    Use your own OpenAI API key (Optional with <b>PRO Plan</b>)
                  </span>
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px",
    gap: "20px",
  },
  logo: {
    width: 28,
  },
  title: {
    maxWidth: 380,
    textAlign: "center",
  },
  buttonContainer: {
    display: "flex",
    gap: 10,
    justifyContent: "center",
    flexWrap: window.innerWidth > 1080 ? undefined : "wrap",
    width: "100%",
  },
  card: {
    display: "flex",
    justifyContent: "center",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 20,
  },
  cardTitle: {
    fontSize: 20,
  },
  cardDescription: {
    color: "#6B6B6B",
    fontSize: 15,
  },
  cardButtonContainer: {
    display: "flex",
    gap: 5,
    flexDirection: "column",
    margin: "26px 0",
  },
  cardButton: {
    color: "white",
    display: "flex",
    width: "100%",
  },
  cardFeatureContainer: {
    display: "flex",
    gap: 10,
    margin: 5,
  },
  cardFeatureIcon: {
    color: "green",
  },
  divider: {
    width: 1,
    backgroundColor: "#E5E5E5",
    height: "auto",
    margin: "0 26px",
  },
};

export default Pricing;
