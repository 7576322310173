import { Link } from "react-router-dom";
import { Card } from "antd";

import MainMenu from "./MainMenu";

const CommonPage = ({ title, extraHeader, children }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 10, padding: 10 }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link to="/home">
          <img style={{ width: 22 }} alt="chat2course" src="/logo.png" />
        </Link>
        <MainMenu />
      </div>
      <Card style={{ border: 0, backgroundColor: "#c3e3ff", padding: 4 }}>
        <h2 style={{ fontFamily: "Outfit" }}>{title}</h2>
        {extraHeader}
      </Card>
      {children}
    </div>
  );
};

export default CommonPage;
