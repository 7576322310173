const TokenInfo = () => {
  const containerStyle = {
    padding: 10,
  };

  const sectionStyle = {};

  const headingStyle = {
    fontSize: 17,
  };

  const listStyle = {
    padding: "0 0 0 20px",
  };

  const listItemStyle = {};

  const bulletStyle = {};

  return (
    <div style={containerStyle}>
      <div style={sectionStyle}>
        <h2 style={headingStyle}>Token Usage:</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <span style={bulletStyle}></span>
            Tokens are digital credits that enable access to AI-powered features
          </li>
          <li style={listItemStyle}>
            <span style={bulletStyle}></span>
            Used for:
            <ul style={listStyle}>
              <li style={listItemStyle}>Course initialization</li>
              <li style={listItemStyle}>Module and lesson generation</li>
              <li style={listItemStyle}>Content creation</li>
              <li style={listItemStyle}>AI assistance</li>
            </ul>
          </li>
        </ul>
      </div>

      <div style={sectionStyle}>
        <h2 style={headingStyle}>Course Creation Capacity:</h2>
        <ul style={listStyle}>
          <li style={listItemStyle}>
            <span style={bulletStyle}></span>
            100k tokens typically generates either:
            <ul style={listStyle}>
              <li style={listItemStyle}>
                One comprehensive course (~40 lessons)
              </li>
              <li style={listItemStyle}>Multiple shorter courses</li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TokenInfo;
