import { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import ContentViewer from "../template/ContentViewer";
import { post } from "../../utils/fetch";

const CourseCover = () => {
  const { lessonId } = useParams();
  const [delta, setDelta] = useState({});
  const [html, setHtml] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    getCoverData();
  }, []);

  const getCoverData = async () => {
    await post("/pdf-get-lesson-details", {
      lessonId,
    })
      .then((res) => {
        setDelta(res.data.delta);
        setHtml(res.data.html);
      })
      .catch((err) => {
        setError(JSON.stringify({ error: err }));
      });
  };

  return (
    <div style={{ width: "100%", height: "100%", backgroundColor: "white" }}>
      {error && <div>{error}</div>}
      <div style={{ margin: "auto", width: 796, backgroundColor: "white" }}>
        <ContentViewer delta={delta} html={html} />
      </div>
    </div>
  );
};

export default CourseCover;
