import React from "react";
import { Link } from "react-router-dom";
import MainMenu from "./MainMenu";

const Header = ({ children }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 10, padding: 10 }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link to="/home">
          <img style={{ width: 22 }} alt="chat2course" src="/logo.png" />
        </Link>
        <MainMenu />
      </div>
      {children}
    </div>
  );
};

export default Header;
