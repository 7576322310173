import React from "react";
import CodeBlock from "./CodeBlock";
import Markdown from "./Markdown";

const ConversationItem = ({ conversation }) => {
  return (
    <div style={{ padding: "0 6px" }}>
      <h4>Question</h4>
      <Markdown className="chat-markdown" components={{ code: CodeBlock }}>
        {conversation.question}
      </Markdown>
      <h4>Answer</h4>
      <Markdown className="chat-markdown" components={{ code: CodeBlock }}>
        {conversation.answer}
      </Markdown>
    </div>
  );
};

export default ConversationItem;
