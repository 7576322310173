/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Collapse } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { SendOutlined } from "@ant-design/icons";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

import "./learn.css";
import { post } from "../../utils/fetch";
import useScrollToTop from "../../utils/useScrollToTop";
import { abbreviateNumber, truncateString } from "../../utils/utils";
import ConversationItem from "../../components/ConversationItem";
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from "../../actions";
import { AppContext } from "../../App";
import CodeBlock from "../../components/CodeBlock";
import Markdown from "../../components/Markdown";

const { TextArea } = Input;
const { Panel } = Collapse;

const TutorTab = ({
  viewedContent,
  course,
  chatSession,
  setChatSession,
  chatHistory,
  setChatHistory,
}) => {
  useScrollToTop();
  const { isActionInProgress, setError } = useContext(PageContext);
  const { courseId } = useParams();
  const { addAction, removeAction } = usePageActionHandler();
  const { appState } = useContext(AppContext);
  const { user: userData } = appState;

  const [chatInput, setChatInput] = useState(null);
  const [tokenLeft, setTokenLeft] = useState(null);
  const [reply, setReply] = useState(null);
  const [showHistory, setShowHistory] = useState(null);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    if (appState?.user) {
      socket.emit("subscribeToStatusUpdate", appState?.user?.id);
      socket.on("statusUpdate", (socketData) => {
        const { data } = socketData;
        if (data.action === "tokenUpdate") {
          setTokenLeft(data.data.tokenLeft);
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [appState?.user]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    if (courseId) {
      socket.emit("subscribeToStatusUpdate", `${courseId}-${userData?.id}`);
      socket.on("statusUpdate", (socketData) => {
        const { data } = socketData;
        if (data.action === "streamToTutorChat") {
          setReply(data.data.reply);
        }
        if (data.action === "replyToCourseChat") {
          removeAction("replyToCourseChat");
        }
        if (
          data.action === "replyToCourseChat" &&
          viewedContent.id === data.keypointId
        ) {
          setChatSession(data.chatSession || []);
        }
      });
    }

    return () => {
      socket.disconnect();
    };
  }, [courseId, viewedContent?.id]);

  const topicChat = async () => {
    if (!userData?.id) {
      setError("Please sign in to continue.");
      return;
    }

    setReply(null);
    const action =
      course.user_id === userData?.id ? "askTutor" : "participantAsk";
    addAction(action);
    await post(actionTypes[action].api, {
      courseId,
      keypointId: viewedContent.id,
      question: chatInput,
      apiKey: localStorage.getItem("openAiApiKeyV2"),
      aiModel: localStorage.getItem("aiModel"),
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
        addAction("replyToCourseChat");
      });
  };

  const newChatSession = async () => {
    setReply(null);
    const chatSessionCopy = [...chatSession];
    const action = `clearAiTutorSession`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId,
      keypointId: viewedContent.id,
      chatSession: chatSessionCopy,
    })
      .then(() => {
        setChatHistory([...chatHistory, ...chatSessionCopy]);
        setChatSession([]);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  const disableChat =
    isActionInProgress("replyToCourseChat") ||
    isActionInProgress(
      `${process.env.REACT_APP_SAM_ENDPOINT}/course-chat-handler`,
    );

  return (
    <div style={{ padding: 10 }}>
      <Button
        onClick={() => {
          setShowHistory(!showHistory);
        }}
        size="small"
        type="link"
        style={{
          display: "flex",
          marginRight: 20,
          marginLeft: "auto",
          marginBottom: 10,
        }}
      >
        {showHistory ? "Chat" : "History"}
      </Button>
      {showHistory ? (
        <Collapse
          style={{ height: "calc(100vh - 72px)", overflow: "auto" }}
          ghost
          onChange={(key) => {}}
        >
          {chatHistory?.map((item, index) => (
            <Panel key={item.id} header={truncateString(item.question, 100)}>
              <ConversationItem conversation={item} />
            </Panel>
          ))}
        </Collapse>
      ) : (
        <>
          {chatSession.length === 0 && !reply && (
            <p
              style={{
                padding: "0 20px",
                fontSize: 14,
                height: "calc(100vh - 332px)",
                overflow: "auto",
              }}
            >
              Hello, I'm your go-to assistant for current topic discussions.
              Feel free to ask me any questions about the topic.
            </p>
          )}
          <div
            style={{
              display: chatSession.length > 0 || reply ? "block" : "none",
              height: "calc(100vh - 332px)",
              overflow: "auto",
            }}
          >
            {chatSession.length > 0 && (
              <Collapse ghost onChange={(key) => {}}>
                {chatSession?.map((item, index) => (
                  <Panel
                    key={item.id}
                    header={truncateString(item.question, 100)}
                  >
                    <ConversationItem conversation={item} />
                  </Panel>
                ))}
              </Collapse>
            )}
            {reply && (
              <div style={{ borderRadius: 5, padding: 20 }}>
                <Markdown
                  className="chat-markdown"
                  components={{ code: CodeBlock }}
                >
                  {reply}
                </Markdown>
              </div>
            )}
          </div>
          <div
            style={{
              marginTop: 10,
              position: "relative",
              padding: "0 10px",
              display: "flex",
              gap: 10,
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "space-between",
                alignItems: "end",
              }}
            >
              <a href="/account" target="_blank">
                <Button size="small">
                  Tokens{" "}
                  {abbreviateNumber(
                    tokenLeft || appState?.tokenPlan?.no_of_token_left,
                  )}
                </Button>
              </a>
              <div
                style={{
                  display: "flex",
                  gap: 5,
                  zIndex: 1,
                  position: "absolute",
                  right: 30,
                  bottom: 140,
                }}
              >
                <Button
                  style={{ backgroundColor: "white" }}
                  onClick={topicChat}
                  icon={<SendOutlined />}
                  disabled={disableChat || !chatInput}
                  loading={disableChat}
                />
                <Button
                  style={{ backgroundColor: "white" }}
                  disabled={
                    isActionInProgress("/clear-session") ||
                    chatSession.length === 0
                  }
                  loading={isActionInProgress("/clear-session")}
                  onClick={newChatSession}
                >
                  New session
                </Button>
              </div>
            </div>
            <TextArea
              style={{ paddingTop: 20 }}
              rows={6}
              value={chatInput}
              onChange={(val) => {
                setChatInput(val.target.value);
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default TutorTab;
