import { Alert, Button } from "antd";
import { useState, useEffect, useContext } from "react";

import { AppContext } from "../App";

function NewVersion() {
  const { appActions } = useContext(AppContext);
  const [newVersionAvailable, setNewVersionAvailable] = useState(false);
  const [newVersion, setNewVersion] = useState(null);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      checkVersion();
    }, 600000);
    checkVersion();
    return () => clearInterval(interval);
  }, []);

  const checkVersion = async () => {
    const res = await appActions.getAppSetting();
    const latestVersion = res.appSettings.find(
      (item) => item.id === "app-version",
    )?.value;
    const maintenanceMode = res.appSettings.find(
      (item) => item.id === "maintenance-mode",
    );
    const localVersion = localStorage.getItem("appVersion");
    setMaintenanceMode(maintenanceMode.value === "1");

    if (localVersion === null) {
      localStorage.setItem("appVersion", latestVersion);
      window.location.reload();
    } else if (localVersion !== latestVersion) {
      setNewVersionAvailable(true);
      setNewVersion(latestVersion);
    }
  };

  if (!newVersionAvailable && !maintenanceMode) {
    return null;
  }

  return (
    <div style={{ position: "absolute", width: "100%" }}>
      {maintenanceMode && (
        <Alert
          description="View mode only. Website under maintenance"
          type="info"
          action={
            <div style={{ display: "flex", gap: 5 }}>
              <Button
                onClick={() => {
                  setMaintenanceMode(false);
                }}
                size="small"
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  window.location.reload();
                }}
                size="small"
                type="primary"
              >
                Refresh
              </Button>
            </div>
          }
        />
      )}
      {newVersionAvailable && (
        <Alert
          description="New version of the app available. Please press OK to reload the page."
          type="info"
          action={
            <Button
              onClick={() => {
                // localStorage.setItem("appVersion", newVersion);
                localStorage.clear();
                window.location.reload();
              }}
              size="small"
              type="primary"
            >
              Ok
            </Button>
          }
        />
      )}
    </div>
  );
}

export default NewVersion;
