import { Button, Form, Input } from "antd";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { GoogleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import firebaseApp from "../../firebase.js";
import { useContext } from "react";
import useScrollToTop from "../../utils/useScrollToTop.js";
import { PageContext } from "./Auth.jsx";

const auth = getAuth(firebaseApp);

const Login = () => {
  const { handleRemoveFetchItems, handleSetFetchItems, showErrorMsg } =
    useContext(PageContext);
  const history = useHistory();
  useScrollToTop();

  const provider = new GoogleAuthProvider();
  const googleSignIn = () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const user = result.user;
        localStorage.setItem("accessToken", user.accessToken);
        localStorage.setItem("email", user.email);
        history.push("/studio/projects");
      })
      .catch((err) => {
        showErrorMsg(err);
      });
  };

  const signUp = async (values) => {
    handleSetFetchItems("/signin/createUserWithEmailAndPassword");
    await signInWithEmailAndPassword(auth, values.email, values.password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        localStorage.setItem("accessToken", user.accessToken);
        localStorage.setItem("email", user.email);
        history.push("/studio/projects");
      })
      .catch((error) => {
        showErrorMsg(error);
      });
    handleRemoveFetchItems("/signin/createUserWithEmailAndPassword");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Form
        name="basic"
        style={{
          width: "100%",
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={signUp}
        autoComplete="off"
      >
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <h3>Login</h3>
          <Button
            onClick={googleSignIn}
            type="primary"
            icon={<GoogleOutlined />}
          />
        </div>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Password"
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
