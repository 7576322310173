import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import "react-markdown-editor-lite/lib/index.css";
import "./markdown.css";

const Markdown = ({
  className = "markdown-component",
  content,
  children,
  components,
}) => {
  return (
    <div className={className}>
      <ReactMarkdown components={components}>
        {content || children}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
