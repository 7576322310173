import { useHistory } from "react-router-dom";

const ProtectPage = ({ children }) => {
  const accessToken = localStorage.getItem("accessToken");
  const history = useHistory();

  if (!accessToken) {
    localStorage.clear();
    history.push("/signin");
    return;
  }

  return children;
};

export default ProtectPage;
