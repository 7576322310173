import { Tag } from "antd";

const CustomTag = (props) => {
  return (
    <Tag
      {...props}
      style={{
        display: "flex",
        margin: 0,
        alignItems: "center",
        ...(props.style || {}),
      }}
    >
      {props.children}
    </Tag>
  );
};

export default CustomTag;
