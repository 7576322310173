/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Card, Input } from "antd";
import { useContext, useState } from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuidv4 } from "uuid";
import { LeftOutlined } from "@ant-design/icons";

import "./project.css";
import { PageContext } from "../../components/Page";
import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import { usePageActionHandler } from "../../components/Page";

const { TextArea } = Input;

const AddNewCourse = ({ height }) => {
  const { isActionInProgress, setError } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const history = useHistory();
  const { projectId } = useParams();

  const [label, setLabel] = useState(null);
  const [isHandsOn, setIsHandsOn] = useState(false);
  const [page, setPage] = useState("give-title");
  const [goal, setGoal] = useState(null);

  const createCourse = async () => {
    const action = "initializeCourse";
    addAction(action, actionTypes[action].fetchMessage);
    return await post(actionTypes[action].api, {
      apiKey: localStorage.getItem("openAiApiKeyV2"),
      aiModel: localStorage.getItem("aiModel"),
      projectId,
      name: label,
      isHandsOn,
      goal: page === "quick-setup" ? goal : undefined,
    })
      .then(async (res) => {
        localStorage.removeItem("courseOverviewConversation");
        localStorage.removeItem("sessionId");
        localStorage.removeItem("projectId");
        localStorage.removeItem("courseId");
        localStorage.removeItem("courseTitle");
        localStorage.removeItem("handsOn");
        return res.data;
      })
      .catch((err) => {
        removeAction(action);
        setError(err);
      });
  };

  const generateCourseOverview = async (data) => {
    const action = `generateCourseOverview`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: data.id,
      projectId: projectId,
      apiKey: localStorage.getItem("openAiApiKeyV2"),
      aiModel: localStorage.getItem("aiModel"),
    })
      .then(() => {
        history.push(`/project/${projectId}/overview/${data.id}`);
      })
      .catch((err) => {
        setError(err);
        removeAction(action);
      });
  };

  if (page === "quick-setup") {
    return (
      <div className="studio-add-new" style={{ height }}>
        <Button
          icon={<LeftOutlined />}
          shape="circle"
          onClick={() => {
            setPage("select-method");
          }}
          disabled={isActionInProgress([
            "getProject",
            "getCourseDetails",
            "initializeProjectCourse",
          ])}
        />
        <h2 style={{ textAlign: "center" }}>Course details</h2>
        <TextArea
          rows={10}
          placeholder="Describe the learning goals & any other details about the course"
          style={{ marginBottom: 20 }}
          allowClear
          maxLength={2000}
          showCount
          onChange={(text) => {
            setGoal(text.target.value);
          }}
        />
        <Button
          disabled={isActionInProgress([
            "getProject",
            "getCourseDetails",
            "initializeProjectCourse",
          ])}
          loading={isActionInProgress([
            "getCourseDetails",
            "initializeProjectCourse",
          ])}
          onClick={async () => {
            await createCourse().then(async (data) => {
              await generateCourseOverview(data);
            });
          }}
        >
          Create
        </Button>
      </div>
    );
  }

  if (page === "select-method") {
    return (
      <div className="studio-add-new" style={{ height }}>
        <Button
          icon={<LeftOutlined />}
          shape="circle"
          onClick={() => {
            setPage("give-title");
          }}
          disabled={isActionInProgress([
            "getProject",
            "getCourseDetails",
            "initializeProjectCourse",
          ])}
        />
        <h2 style={{ textAlign: "center" }}>Choose setup</h2>
        <div
          style={{ display: "flex", gap: 10, justifyContent: "space-between" }}
        >
          <Card
            hoverable
            style={{ width: "100%" }}
            onClick={() => {
              setPage("quick-setup");
            }}
          >
            <h3 style={{ textAlign: "center" }}>Quick setup</h3>
          </Card>
          <Card
            onClick={async () => {
              await createCourse().then((data) => {
                history.push(`/project/${projectId}/course/${data.id}`);
              });
              return;
            }}
            hoverable
            style={{ width: "100%" }}
          >
            <h3 style={{ textAlign: "center" }}>Chatbot-assisted</h3>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="studio-add-new" style={{ height }}>
      <h2 style={{ textAlign: "center" }}>Let's create your course</h2>
      <div style={{ display: "flex", justifyContent: "end" }}>
        {/* <Checkbox
          checked={isHandsOn}
          onChange={
            (e) => {
              let openAiApiKey = localStorage.getItem('openAiApiKeyV2');
              let currentAiModel = localStorage.getItem('aiModel');
              if (openAiApiKey && currentAiModel !== "gpt-4") {
                setError("Hands-on course only available with GPT4 model")
                setIsHandsOn(false);
                return;
              }
              setIsHandsOn(e.target.checked)
            }
          }
        >
          Hands-on course (Programming)
        </Checkbox> */}
      </div>
      <Input
        placeholder="Course label"
        onChange={(val) => {
          setLabel(val.target.value);
        }}
      />
      <Button
        onClick={async () => {
          if (!label) {
            setError("Insert your course label");
            return;
          }

          if (!localStorage.getItem("accessToken")) {
            const uuid = uuidv4();
            localStorage.setItem("sessionId", uuid);
            localStorage.setItem("courseTitle", label);
            localStorage.setItem("handsOn", isHandsOn);
            history.push("/");
            return;
          }

          if (isHandsOn) {
            await createCourse().then((data) => {
              history.push(`/project/${projectId}/course/${data.id}`);
            });
            return;
          }

          setPage("select-method");
        }}
      >
        Next
      </Button>
    </div>
  );
};

export default AddNewCourse;
