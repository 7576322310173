import samApis from "./api.json";

const backgroundJobApis = samApis[process.env.REACT_APP_ENV];
// const defaultActionMessage = 'Action in progress..';

const actionTypes = {
  updateCourseCover: {
    api: `/update-course-cover`,
  },
  claimRedemptionCode: {
    api: `/account/claim-redemption-code`,
  },
  getCoverTemplates: {
    api: `/get-cover-templates`,
  },
  createUploadImgPresigned: {
    api: `/create-upload-img-presigned`,
  },
  getPrompts: {
    api: `/admin/get-prompts`,
  },
  participantAsk: {
    api: backgroundJobApis.participantAsk,
  },
  toggleHandsOn: {
    api: "/toggle-hands-on",
  },
  chat: {
    api: "/chat/collect-course-details",
  },
  updateCourseVisibility: {
    api: "/update-course-visibility",
  },
  createUser: {
    api: "/create-user",
  },
  generateCourseOverview: {
    api: backgroundJobApis.generateCourseOverview,
  },
  getCourse: {
    api: "/get-course",
  },
  getCourseDetails: {
    api: "/get-course-details",
  },
  generateModules: {
    api: backgroundJobApis.generateModules,
    confirmationText:
      "This will remove & regenerate all the modules. Press confirm to proceed.",
  },
  generateHandsOnModules: {
    api: backgroundJobApis.generateHandsOnModules,
    confirmationText:
      "This will remove & regenerate all the modules. Press confirm to proceed.",
  },
  generateSubmodules: {
    api: backgroundJobApis.generateSubmodules,
    confirmationText:
      "This will remove & regenerate lessons for this module. Press confirm to proceed.",
  },
  generateContent: {
    api: backgroundJobApis.generateContent,
    confirmationText:
      "This will remove & regenerate the lesson content. Press confirm to proceed.",
  },
  getSubmoduleContent: {
    api: "/get-keypoint-content",
  },
  getUser: {
    api: "/get-user",
  },
  createProject: {
    api: "/create-project",
    fetchMessage: "Creating your project..",
  },
  initializeCourse: {
    api: "/initialize-course",
  },
  getProjects: {
    api: "/get-projects",
  },
  getProject: {
    api: "/get-project",
  },
  deleteCourse: {
    api: "/delete-course",
    confirmationText: "WARNING! Delete course. Press confirm to proceed.",
  },
  addNewCourseModule: {
    api: "/add-course-module",
  },
  sortCourseModules: {
    api: "/sort-modules",
  },
  addNewCourseSubmodule: {
    api: "/add-course-submodule",
  },
  sortCourseSubmodules: {
    api: "/sort-submodules",
  },
  updateCourseModule: {
    api: "/update-course-module",
  },
  deleteModule: {
    confirmationText: "WARNING! Delete module. Press confirm to proceed.",
  },
  deleteSubmodule: {
    confirmationText: "WARNING! Delete lesson. Press confirm to proceed.",
  },
  deleteProject: {
    api: "/delete-project",
    confirmationText: "WARNING! Delete project. Press confirm to proceed.",
  },
  deleteProjectCourses: {
    api: "/delete-project-courses",
    confirmationText:
      "WARNING! Delete all project courses. Press confirm to proceed.",
  },
  getUserActivity: {
    api: `/get-user-activity`,
  },
  askTutor: {
    api: backgroundJobApis.chatTutor,
  },
  clearAiTutorSession: {
    api: "/clear-session",
  },
  updateCourseOverview: {
    api: "/update-course-overview",
  },
  updateSubmoduleContent: {
    api: "/update-keypoint-content",
  },
  updateSubmoduleBasicDetails: {
    api: "/update-submodule-basic-details",
  },
  createTokenPaymentLink: {
    api: "/create-token-payment-link",
  },
  downloadCoursePdf: {
    api: `/trigger-download-course-pdf`,
  },
};

export { actionTypes };
