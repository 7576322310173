import React, { useState } from "react";
import { useQuill } from "react-quilljs";
import { SaveOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import { ImageActions } from "@xeger/quill-image-actions";
import { ImageFormats } from "@xeger/quill-image-formats";

import CustomTag from "../../components/CustomTag";

import "quill/dist/quill.bubble.css";
import "./coverEditor.css";

const CoverEditor = ({
  delta: _delta = "",
  onSave,
  onUploadImg,
  backgroundStyles,
  selected,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [delta, setDelta] = useState(_delta);
  const { quill, quillRef, Quill } = useQuill({
    theme: "bubble",
    modules: {
      imageActions: {},
      imageFormats: {},
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["link", "image"],
        [{ color: [] }, { background: [] }],
        ["clean"],
      ],
      clipboard: {
        matchVisual: false,
      },
    },
    formats: [
      "background",
      "bold",
      "color",
      "code",
      "italic",
      "link",
      "size",
      "strike",
      "script",
      "underline",
      "blockquote",
      "header",
      "indent",
      "list",
      "align",
      "direction",
      "code-block",
      "formula",
      "image",
      "height",
      "width",
      "float",
    ],
  });

  if (Quill && !quill) {
    const block = Quill.import("blots/block");
    block.tagName = "div";
    Quill.register(block);
    Quill.register("modules/imageActions", ImageActions);
    Quill.register("modules/imageFormats", ImageFormats);
  }

  const insertToEditor = (url) => {
    const range = quill.getSelection();
    quill.insertEmbed(range.index, "image", url);
  };

  const saveToServer = async (file) => {
    const body = new FormData();
    body.append("file", file);
    const imgUrl = await onUploadImg(file);
    insertToEditor(imgUrl);
  };

  const selectLocalImage = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = () => {
      const file = input.files[0];
      saveToServer(file);
    };
  };

  React.useEffect(() => {
    if (quill) {
      setDelta(_delta);
      quill.setContents(_delta);
      quill.getModule("toolbar").addHandler("image", selectLocalImage);
      quill.enable(false);
      setIsEditMode(false);
    }
  }, [quill, _delta]);

  return (
    <div style={{ maxWidth: 800, width: "100%", margin: "auto" }}>
      {window.innerWidth > 500 && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            gap: 5,
            backgroundColor: "#EBECF0",
          }}
        >
          {selected && <CustomTag color="blue">Current</CustomTag>}
          <Button
            danger={isEditMode}
            onClick={() => {
              setIsEditMode(!isEditMode);
              quill.enable(!isEditMode);
              if (isEditMode) {
                quill.setContents(delta);
              }
            }}
          >
            {isEditMode ? "Quit editor " : "Edit template"}
          </Button>
          <Button
            disabled={selected && !isEditMode}
            icon={<SaveOutlined />}
            onClick={async () => {
              const content = quill.getContents();
              await onSave(content);
              setDelta(content);
            }}
          >
            {isEditMode ? (selected ? "Save" : "Use & save") : "Use template"}
          </Button>
        </div>
      )}
      {window.innerWidth < 900 && (
        <div style={{ backgroundColor: "#EBECF0", marginTop: 10 }}>
          <Alert
            type="info"
            message={
              window.innerWidth > 500
                ? "If you're viewing this on a small screen and the template appears incorrectly formatted, try zooming out for a better display"
                : "Cover editor is not supported for this screen size."
            }
          />
        </div>
      )}
      {window.innerWidth > 500 && (
        <div className="cover-editor" style={{ position: "relative" }}>
          <div style={{ ...backgroundStyles, marginTop: 10 }} ref={quillRef} />
          <div
            style={{
              display: isEditMode ? "none" : "block",
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default CoverEditor;
