import { Button, Tag, Tooltip } from "antd";
import { SaveOutlined, CloseOutlined, FileImageOutlined, ItalicOutlined, BoldOutlined, UnderlineOutlined, StrikethroughOutlined, AlignLeftOutlined, OrderedListOutlined, UnorderedListOutlined, LinkOutlined } from "@ant-design/icons";

const TooltipComp = () => {
  return (
    <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap', justifyContent: 'center' }}>
      <Button type='text' style={{ color: 'lightgrey' }} icon={<BoldOutlined style={{ fontSize: 18 }} />} />
      <Button type='text' style={{ color: 'lightgrey' }} icon={<ItalicOutlined style={{ fontSize: 18 }} />} />
      <Button type='text' style={{ color: 'lightgrey' }} icon={<UnderlineOutlined style={{ fontSize: 18 }} />} />
      <Button type='text' style={{ color: 'lightgrey' }} icon={<StrikethroughOutlined style={{ fontSize: 18 }} />} />
      <Button type='text' style={{ color: 'lightgrey' }} icon={<AlignLeftOutlined style={{ fontSize: 18 }} />} />
      <Button type='text' style={{ color: 'lightgrey' }} icon={<OrderedListOutlined style={{ fontSize: 18 }} />} />
      <Button type='text' style={{ color: 'lightgrey' }} icon={<UnorderedListOutlined style={{ fontSize: 18 }} />} />
      <Button type='text' style={{ color: 'lightgrey' }}>Heading 1</Button>
      <Button type='text' style={{ color: 'lightgrey' }} icon={<LinkOutlined style={{ fontSize: 18 }} />} />
      <Button type='text' style={{ color: 'lightgrey' }} icon={<FileImageOutlined style={{ fontSize: 18 }} />} />
    </div>
  )
}

const MockEditor = () => {

  return (
    <div style={{ padding: '20px 40px', boxShadow: 'rgba(171, 171, 171, 0.2) -20px -18px 0px 0px', borderRadius: 10 }}>
      <div style={{ display: 'flex', gap: 5, marginBottom: 16, justifyContent: 'flex-end' }}>
        <Tag color="blue" style={{ display: 'flex', alignItems: 'center' }}>Edit mode</Tag>
        <Button icon={<SaveOutlined />} />
        <Button icon={<CloseOutlined />} />
      </div>
      <div style={{ margin: '26px 0 18px 0' }}>
        <i style={{
          fontFamily: '"Source Serif Pro",serif',
          fontSize: '18px',
        }}>
          Module 1: Introduction to personal finance for couple
        </i>
      </div>
      <h1
        style={{
          fontFamily: 'Outfit,serif',
          fontSize: '40px',
          fontWeight: '700',
          lineHeight: '1.2',
          marginBottom: '32px'
        }}
      >
        1.1 The Importance of <Tooltip open overlayInnerStyle={{ width: window.innerWidth > 900 ? 460 : 240, backgroundColor: '#373636' }} placement={window.innerWidth > 900 ? "bottomRight" : 'bottom'} defaultOpen title={TooltipComp}><mark style={{ backgroundColor: '#3367D1', color: 'white' }}>Financial</mark></Tooltip> Planning as a Couple
      </h1>
      <p style={{
        fontFamily: '"Source Serif Pro",serif',
        fontSize: '18px',
        lineHeight: '1.5',
        marginBottom: '20px'
      }}>
        Financial planning is a crucial aspect of any individual's life. However, when it comes to couples, the importance of financial planning becomes even more significant. In this lesson, we will explore why financial planning is essential for couples and how it can help you build a strong financial foundation together.
      </p>
    </div>
  )
}

export default MockEditor;
