import React, { useContext, useEffect, useState } from "react";
import { Button, Card } from "antd";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

import "./index.css";
import useScrollToTop from "../../utils/useScrollToTop";
import ProjectCard from "./ProjectCard";
import MainMenu from "../../components/MainMenu";
import { post } from "../../utils/fetch";
import { PageContext, usePageActionHandler } from "../../components/Page";
import { actionTypes } from "../../actions";

const Project = () => {
  useScrollToTop();
  const { setError } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [projects, setProjects] = useState([]);
  const { contentId } = useParams();

  useEffect(() => {
    getProjects();
  }, []);

  const getProjects = async () => {
    const action = "getProjects";
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: contentId,
    })
      .then((res) => {
        setProjects(res.data.projects);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
      });
  };

  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 10, padding: 10 }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Link to="/home">
          <img style={{ width: 22 }} alt="chat2course" src="/logo.png" />
        </Link>
        <MainMenu />
      </div>
      <Card style={{ border: 0, backgroundColor: "#c3e3ff", padding: 4 }}>
        <h2 style={{ fontFamily: "Outfit" }}>Projects</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: 20,
            alignItems: "end",
            flexDirection: window.innerWidth > 600 ? "row" : "column",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: 10,
              alignItems: "baseline",
              flexDirection: window.innerWidth > 600 ? "row" : "column",
            }}
          >
            <span style={{ maxWidth: 900 }}>
              Project is where you can organize your courses into different
              categories.
            </span>
          </div>
          <Link to="/studio/project/new">
            <Button>Add new project</Button>
          </Link>
        </div>
      </Card>
      <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
        {projects?.map((item) => {
          return <ProjectCard project={item} />;
        })}
      </div>
    </div>
  );
};

export default Project;
