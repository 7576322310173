import { Button, Form, Input } from "antd";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import firebaseApp from "../../firebase.js";
import { useContext, useState } from "react";
import useScrollToTop from "../../utils/useScrollToTop.js";
import { PageContext } from "./Auth.jsx";

const auth = getAuth(firebaseApp);

const Forgot = () => {
  const { handleRemoveFetchItems, handleSetFetchItems, showErrorMsg } =
    useContext(PageContext);
  const [isDone, setIsDone] = useState(false);
  useScrollToTop();

  const sentEmail = (values) => {
    handleSetFetchItems("/signin/forgotPassword");
    sendPasswordResetEmail(auth, values.email)
      .then(() => {
        setIsDone(true);
      })
      .catch((error) => {
        showErrorMsg(error.message);
      });
    handleRemoveFetchItems("/signin/forgotPassword");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Form
        name="basic"
        style={{
          width: "100%",
          maxWidth: 600,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={sentEmail}
        autoComplete="off"
      >
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <h3>Forgot password</h3>
        </div>
        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Please input your email!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
        {isDone && (
          <span style={{ color: "green" }}>
            Password forgot instruction sent. Please check your email.
          </span>
        )}
      </Form>
    </div>
  );
};

export default Forgot;
