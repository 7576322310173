import { Alert, Button, Input } from "antd";
import { useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import { SendOutlined } from "@ant-design/icons";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";

import CodeBlock from "../../components/CodeBlock";
import Markdown from "../../components/Markdown";
import { PageContext, usePageActionHandler } from "../../components/Page";
import { post } from "../../utils/fetch";
import { actionTypes } from "../../actions";
import { AppContext } from "../../App";
import TooltipButton from "../../components/TooltipButton";
import "./chat.css";

const { TextArea } = Input;

const intro = `
Hello!

I'm here to assist you in creating a personalized course tailored to your needs and preferences. To ensure the course is highly relevant and impactful, I'll be asking you a series of questions to gather essential details. If you're unsure about any answers, feel free to skip the question or ask for suggestions.

Let's begin by determining your preferred language of instruction for the course. What language would you like the course to be taught in?`;

const Home = () => {
  const chatRef = useRef();
  const chatEndRef = useRef();
  const history = useHistory();
  const params = useParams();
  const { appState } = useContext(AppContext);
  const { setError, isActionInProgress } = useContext(PageContext);
  const { addAction, removeAction } = usePageActionHandler();
  const [aiResponse, setAiResponse] = useState(null);
  const [courseOverviewConversation, setCourseOverviewConversation] = useState(
    JSON.parse(localStorage.getItem("courseOverviewConversation")) || [],
  );
  const sessionId = params.courseId || localStorage.getItem("sessionId");
  const [course, setCourse] = useState(null);
  const [chatInputKey, setChatInputKey] = useState(Date.now());
  const [hideUpgradeNotes, setHideUpgradeNotes] = useState(
    localStorage.getItem("hideUpgradeNotes"),
  );
  const openAiApiKey = localStorage.getItem("openAiApiKeyV2");
  const currentPlan = appState?.[openAiApiKey ? "selfApiPlan" : "tokenPlan"];

  useEffect(() => {
    if (course?.overview) {
      scrollToBottom();
    }
  }, [course?.overview]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SOCKETIO);
    scrollToBottom();

    if (
      localStorage.getItem("accessToken") &&
      (!params.projectId || !sessionId)
    ) {
      history.push("/studio/projects");
      return;
    }

    if (!sessionId) {
      history.push("/home");
      return;
    }

    if (params.courseId) {
      getCourse();
    }

    if (!params.projectId && localStorage.getItem("projectId")) {
      history.push(`/project/${localStorage.getItem("projectId")}}`);
      return;
    }

    if (
      !params.courseId &&
      localStorage.getItem("courseId") &&
      localStorage.getItem("courseId") !== "null" &&
      localStorage.getItem("projectId") &&
      localStorage.getItem("projectId") !== "null"
    ) {
      history.push(
        `/project/${localStorage.getItem("projectId")}/course/${localStorage.getItem("courseId")}`,
      );
      return;
    }

    if (sessionId) {
      handleSetConversation(courseOverviewConversation);
      socket.emit("subscribeToStatusUpdate", sessionId);
      socket.on("statusUpdate", (socketData) => {
        const { data } = socketData;
        if (data.error) {
          getCourse();
          if (data.error.code === "not_enough_token") {
            setError(
              "Low token balance. Upgrade or top up your token to continue app access.",
            );
            return;
          }
          setError(data.error);
          return;
        }

        if (data.action === "streamingCourseOverviewChatResponse") {
          setAiResponse(data.data.reply);
          scrollToBottom();
        }

        if (data.action === "completeCourseOverviewChatResponse") {
          setAiResponse(null);
          const latestConversation = [
            ...JSON.parse(localStorage.getItem("courseOverviewConversation")),
            {
              role: "assistant",
              content: data.data.reply,
            },
          ];
          handleSetConversation(latestConversation);
          scrollToBottom();
          removeAction("chat");
        }
      });
    }

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, params.projectId, params.courseId]);

  const handleSetConversation = (conversation) => {
    localStorage.setItem(
      "courseOverviewConversation",
      JSON.stringify(conversation),
    );
    setCourseOverviewConversation(conversation);
  };

  const getCourse = async () => {
    const action = `getCourseDetails`;
    addAction(action);
    await post(actionTypes[action].api, {
      courseId: params.courseId,
    })
      .then((res) => {
        setCourse(res.data.course);
        setCourseOverviewConversation(res.data.course.overview_chat);
      })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        removeAction(action);
        scrollToBottom();
      });
  };

  const handleReply = async () => {
    const action = `chat`;
    addAction(action);
    const reply = [
      ...courseOverviewConversation,
      {
        role: "user",
        content: chatRef.current?.resizableTextArea?.textArea?.value,
      },
    ];
    handleSetConversation(reply);
    await post(actionTypes[action].api, {
      sessionId,
      reply,
      handsOn:
        !localStorage.getItem("accessToken") &&
        localStorage.getItem("handsOn") === "true",
      apiKey: localStorage.getItem("openAiApiKeyV2"),
      aiModel: localStorage.getItem("aiModel"),
    })
      .catch((err) => {
        setError(err);
      })
      .finally(() => {
        setChatInputKey(Date.now());
        scrollToBottom();
      });
  };

  const endSession = async () => {
    const action = `generateCourseOverview`;
    addAction(action);
    const reply = [
      ...courseOverviewConversation,
      {
        role: "user",
        content: "End session",
      },
    ];
    handleSetConversation(reply);
    await post(actionTypes[action].api, {
      courseId: params.courseId,
      projectId: params.projectId,
      courseTitle: !params.courseId && localStorage.getItem("courseTitle"),
      handsOn: !params.courseId && localStorage.getItem("handsOn") === "true",
      conversation: reply,
      apiKey: localStorage.getItem("openAiApiKeyV2"),
      aiModel: localStorage.getItem("aiModel"),
    })
      .then((res) => {
        localStorage.setItem("courseId", res.data.id);
        history.push(`/project/${params.projectId}/overview/${res.data.id}`);
      })
      .catch((err) => {
        setError(err);
        removeAction(action);
      })
      .finally(() => {
        scrollToBottom();
      });
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView();
  };

  return (
    <div
      style={{
        maxWidth: "1080px",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        padding: 10,
      }}
    >
      <div>
        <div
          className="overview-chat"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            overflow: "auto",
            height: "calc(-196px + 100vh)",
          }}
        >
          <div
            style={{
              backgroundColor: "antiquewhite",
              padding: "30px 30px 16px 30px",
              width: "80%",
              borderRadius: 10,
              marginLeft: 10,
              marginRight: "auto",
            }}
          >
            <Markdown
              className="chat-markdown"
              components={{ code: CodeBlock }}
            >
              {intro}
            </Markdown>
          </div>
          {courseOverviewConversation?.map((item) => {
            return (
              <div
                key={item.id}
                style={{
                  backgroundColor:
                    item.role === "user" ? "#d0ebff" : "antiquewhite",
                  padding: "30px 30px 16px 30px",
                  width: "80%",
                  marginLeft: item.role === "user" ? "auto" : 10,
                  marginRight: item.role === "user" ? 10 : "auto",
                  borderRadius: 10,
                }}
              >
                <Markdown
                  className="chat-markdown"
                  components={{ code: CodeBlock }}
                >
                  {item.content}
                </Markdown>
              </div>
            );
          })}
          {aiResponse && (
            <div
              style={{
                backgroundColor: "antiquewhite",
                padding: "30px 30px 16px 30px",
                width: "80%",
                borderRadius: 10,
                marginLeft: 10,
                marginRight: "auto",
              }}
            >
              <Markdown
                className="chat-markdown"
                components={{ code: CodeBlock }}
              >
                {aiResponse}
              </Markdown>
            </div>
          )}
          <div ref={chatEndRef} />
        </div>
        {(!appState?.user?.id ||
          (currentPlan?.is_trial && currentPlan?.no_of_token_left === 30000)) &&
          !hideUpgradeNotes ? (
          <Alert
            message={<b>Welcome!</b>}
            description={
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                }}
              >
                <div>
                  You are currently using basic AI Model. Upgrade to Pro Plan to
                  access advanced AI Model and enhance your experience.
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "end",
                  }}
                >
                  <Button
                    style={{ alignSelf: "end" }}
                    onClick={() => {
                      localStorage.setItem("hideUpgradeNotes", true);
                      setHideUpgradeNotes(true);
                    }}
                  >
                    Use basic AI
                  </Button>
                  <Link to="/home#subscribeSection">
                    <Button style={{ alignSelf: "end" }}>Upgrade</Button>
                  </Link>
                </div>
              </div>
            }
            type="info"
          />
        ) : (
          <div style={{ position: " relative", marginTop: 30 }}>
            <TextArea
              ref={chatRef}
              key={chatInputKey}
              rows={4}
              placeholder={`Enter your answer and click the 'Submit' button. When you are done, click the 'End Session' button to proceed with generating the lessons.`}
              style={{ paddingTop: 20 }}
              maxLength={1000}
              showCount
              disabled={course?.overview}
            />
            <div
              style={{
                position: "absolute",
                zIndex: 99,
                right: 16,
                top: -16,
                display: "flex",
                gap: 5,
                alignItems: "center",
              }}
            >
              {!course?.overview && !course?.learning_goals && (
                <TooltipButton
                  title="Submit"
                  style={{ backgroundColor: "white", opacity: 1 }}
                  type="default"
                  size="medium"
                  disabled={isActionInProgress()}
                  onClick={handleReply}
                  icon={SendOutlined}
                />
              )}
              <Button
                onClick={async () => {
                  if (course?.overview) {
                    history.push(
                      `/project/${params.projectId}/overview/${course.id}`,
                    );
                    return;
                  }
                  if (localStorage.getItem("accessToken")) {
                    await endSession();
                  } else {
                    history.push("/signin");
                  }
                }}
                style={{
                  backgroundColor: course?.overview ? undefined : "white",
                  opacity: 1,
                }}
                disabled={
                  isActionInProgress() ||
                  courseOverviewConversation?.length === 0
                }
              >
                {course?.overview ? "Overview" : "End session"}
              </Button>
              <Button
                onClick={() => {
                  history.push(`/`);
                }}
                style={{ height: 35, width: 35 }}
                shape="circle"
              >
                <img style={{ width: 22 }} alt="chat2course" src="/logo.png" />
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Home;
